import React from "react";
import "./ThankYouModal.css";
import Button from "react-bootstrap/Button";
import ModalLogo from "../assets/images/ModalLogo.svg";
import closeIcon from "../assets/images/closeIcon.svg";
import Modal from "react-bootstrap/Modal";

const ThankYouModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props?.show}
    >
      <Modal.Body className="ThankYouModal">
        <div className="butn">
          <button onClick={props?.onHide} className="text-white">
            <img src={closeIcon} alt="img" />
          </button>
        </div>
        <div className="container py-5 px-lg-5 ">
          <div>
            <div className="imagediv ">
              <img className="" src={ModalLogo} />
            </div>
            <h4 className="mb-4 py-4 mt-4">{props?.heading}</h4>
            {props?.name && <p className="mb-3 p1">Hello, {props?.name}</p>}
            {/* <p className="p1">Subject:</p>
            <p>Test</p> */}
            {/* <p className="mt-2 p1">User Comment :</p> */}
            <p className="text-justify">{props?.paraOne}</p>
            <p className="text-justify">{props?.paraTwo}</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ThankYouModal;
