import React from "react";
import CustomBanner from "../components/CustomBanner";
import ServiceOverview from "../components/ServiceOverview";
import SecurityComplianceAndAuditingPageImg1 from "../assets/images/SecurityComplianceAndAuditingPage/Image 1.svg";
import SecurityComplianceAndAuditingPageImg2 from "../assets/images/SecurityComplianceAndAuditingPage/Image 2.svg";
import SecurityComplianceAndAuditingPageImg3 from "../assets/images/SecurityComplianceAndAuditingPage/Image 3.svg";
import SecurityComplianceAndAuditingPageImg4 from "../assets/images/SecurityComplianceAndAuditingPage/Image 4.svg";
import SecurityComplianceAndAuditingPageImg5 from "../assets/images/SecurityComplianceAndAuditingPage/Image 5.svg";

const SecurityComplianceAndAuditingPage = () => {
  const bannerPrimaryHeadingOne = "Security";
  const bannerPrimaryHeadingTwo = "Compliance";
  const bannerSubHeading = "& Auditing";

  const bannerParagrapghTextOne =
    "Cloud Shield conducts comprehensive assessment and examination of a business's information and security system. As cybersecurity experts, we provide regular audits that help you identify the quality of all web areas to devise a strategy to conduct audits and understand all the weak spots.";

  const bannerParagrapghTextTwo =
    "Our strategy is to create a safe compliance zone by state-of-the-art policies well-integrated with set protocols of your organization. This helps you craft an Informational Technology infrastructure to verify all of your security protocols as well as ensure regulatory compliance to success and scale.";
  const serviceData = [
    {
      heading: "ISO 27001:2013",
      subheading: "Meet The Requirements For Scaling Your Organization",
      description:
        "Cloud Shield implements the ISO 27001:2013 to meet the rising requirements for establishing, implementing, maintaining and improving an IT security management system within your enterprise's set or decided protocols or context. Using the standardized requirements for an Information Security Management System (ISMI), we help elevate your company’s IT policy structure.",
      imageSrc: SecurityComplianceAndAuditingPageImg1,
    },
    {
      heading: "General Data Protection Regulation (GDPR)",
      subheading: "Set The Rights According To Your Requirements",
      description:
        "Cloud Shield is a proficient organization that gathers all the shared information regarding your systems and running applications to check the current conditions. This wouldn't allow any attacker or breacher to scan or enumerate networks or applications on open ports.",
      descriptionwithoutspace:
        "This strategy will safeguard your system names, versions, user accounts, email address, machine names, services versions, or network resources.",
      imageSrc: SecurityComplianceAndAuditingPageImg2,
    },
    {
      heading: "ISO 27017",
      subheading: "Consolidate Your Security Controls To Grow",
      description:
        "Cloud Shield is an expert when it comes to implementing ISO 27017. We aimed to develop a safe cloud-based environment that reduces or nearly eliminates the risk of security problems. We integrate a strategy fully aligned with ISO 27017 standards to remain freely, adapt, and move in a secure and hassle-free cloud environment",
      imageSrc: SecurityComplianceAndAuditingPageImg3,
    },
    {
      heading: "PCI-DSS v3.2.1",
      subheading: "We Help You In Journey From Start To Finish",
      description:
        "Cloud Shield’s professionals help in providing seamless PCI-DSS v3.2.1 certification in a full cycle to get the best results. If your organization handles branded credit cards or significant credentials that could not be breached at any cost, without a doubt, Cloud Shield is the service-providing partner that helps you accomplish your goals in having a secure and stressless atmosphere.",
      imageSrc: SecurityComplianceAndAuditingPageImg4,
    },
    {
      heading: "HIPAA",
      subheading: "Protect The High-Profile Information With HIPAA",
      description:
        "Cloud Shield’s expertise in the Health Insurance Portability and Accountability Act (HIPAA) of 1996 is mainly a law that requires organizations to protest prolific and critical information of clients. This information and data cannot be shared or leaked without the consent or permission of the patient himself. As a security provider, we help you create an ecosystem that protects sensitive and personal information and doesn't let you confront any legal bindings.",
      imageSrc: SecurityComplianceAndAuditingPageImg5,
    },
  ];
  return (
    <div>
      <CustomBanner
        bannerPrimaryHeadingOne={bannerPrimaryHeadingOne}
        bannerPrimaryHeadingTwo={bannerPrimaryHeadingTwo}
        bannerSubHeading={bannerSubHeading}
        bannerParagrapghTextOne={bannerParagrapghTextOne}
        bannerParagrapghTextTwo={bannerParagrapghTextTwo}
      />
      <ServiceOverview serviceData={serviceData} />
    </div>
  );
};

export default SecurityComplianceAndAuditingPage;
