import React from "react";
import CustomBanner from "../components/CustomBanner";
import ServiceOverview from "../components/ServiceOverview";
import ManagedSecurityServicesImg1 from "../assets/images/Managed Security Services/Image 1.svg";
import ManagedSecurityServicesImg2 from "../assets/images/Managed Security Services/Image 2.svg";
import ManagedSecurityServicesImg3 from "../assets/images/Managed Security Services/Image 3.svg";
import ManagedSecurityServicesImg4 from "../assets/images/Managed Security Services/Image 4.svg";
import ManagedSecurityServicesImg5 from "../assets/images/Managed Security Services/Image 5.svg";
import ManagedSecurityServicesImg6 from "../assets/images/Managed Security Services/Image 6.svg";

const ManagedSecurityServicePage = () => {
  const bannerPrimaryHeadingOne = "Managed";
  const bannerPrimaryHeadingTwo = "Security";
  const bannerSubHeading = "Services";

  const bannerParagrapghTextOne =
    " Being a top-class Managed Security Service Provider (MSSP), we provide unparalleled monitoring, management, and handling of security devices and systems. We run our processes first to understand the company's existing security cycle. After that, we implement our cloud-based services, including setting up infrastructure and responding to various incidents. It doesn't matter if you want security services in certain areas or are looking for full outsourcing of your business information security program.";

  const bannerParagrapghTextTwo =
    "We provide incredible solutions for continuous oversight. Moreover, our team of specialists and expert analysts use state-of-the-art tools to counter cyberattacks by setting up a firewall and adopting other options. This helps us create an insurmountable border, which makes it impossible for hackers or breachers to triumph in their approaches. We are proficient in the following areas of Managed Security Services";

  const bannerParagrapghTextThree =
    "With years of precision and blended experience, we have perfected managing the critical infrastructure of organizations of all sizes. Our team of certified professionals offers premium class security services that help customers unlock their true digital potential.m Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using.";

  const serviceData = [
    {
      heading: "Web Application Firewall",
      subheading:
        "Top-Class Business-Specific Web Application Firewall Solutions To Safeguard Your Internet Activities & Presence From Malicious Attacks",
      description:
        "Cloud Shield provides a Web Application Firewall (WAF) that protects your web applications by filtering, monitoring, and blocking any malicious HTTP conversation. This strategy helps us secure your web applications from a variety of application-layer attacks such as cross-site scripting (XSS), Cookie poisoning, and SQL Injection. Our team of experts is well-versed in identifying the attacks that lead to breaches and loss of valuable data and information. During the process, our policies help us craft a customized approach to meet the unique needs of your web application or set of a web application.At Cloud Shield, we provide a-to-z solutions. From installation, deployment, and configuration, our team ensures to provide unparalleled services. Our analysts monitor your web applications and APIs, proceed with requests and data, and finally build a policy that blocks any malicious breach.",
      imageSrc: ManagedSecurityServicesImg3,
    },
    {
      heading: "Vulnerability Inspection",
      subheading:
        "Game-Changing Vulnerability Inspection To Create A Robust Shield In Today’s Critical Digital Age",
      description:
        "Cloud Shield ensures quality inspection. Our assessment protocol determines the unlocked doors, open ports, destructive codes, or loopholes in the system's security, leading to a potential attack or breach. After assessing your web applications and APIs for vulnerabilities, deviations, and exposure, we create a detailed list of security findings presented in a report. This report red flags all the severe damages and dangers that are on the verge of collapse.As a reputed and recognized Managed Security Service Provider, we proactively identify and present real-time conditions. As a result, we counter the current conditions and devise a strategy that doesn’t allow any hackers or breachers to exploit the application.",
      imageSrc: ManagedSecurityServicesImg5,
    },
    {
      heading: "System Hardening",
      subheading:
        "High-Class Endeavors To Strong Foothold In Vulnerable Areas With Methodical Approach",
      description:
        "Cloud Shield is a reliable name when it comes to Managed Service Providers. We harden security across all your web channels to give a rock-solid ecosystem for your business in a cost-effective method.Our team of analysts conducts assessment, evaluation, and audits to determine security configurations for best cloud existence. We strive to incorporate top-notch practices to reduce vulnerability in technology applications, firmware, infrastructure, and systems. The aim is to eliminate potential risks of attack vectors and considering security levels. On top of that, we bring the systematic approach to evaluate, audit, control the potential security levels at the entire organization. Lastly, our system hardening goes in the operating system, network, server, application, and database",
      imageSrc: ManagedSecurityServicesImg4,
    },
    {
      heading: "Threat Detection & Response",
      subheading:
        "Our Resource-Incentive Game Of Detection & Response Is Top-Class",
      description:
        "Cloud Shield is a leading name in cybersecurity services. We use a proactive approach to detect and respond to threats or any malicious activity. Our services and experience in the domain focus on detection, threat hunting, and response to timely react to any arising problem – we name it the resource-intensive game of cleanup and catchup.We drive to reduce alert fatigue, reduce the odds of threats, and retort faster. Acquiring our threat detection and response helps you maximize your return on investment in a safe ecosystem. In order to deliver outstanding services, we use machine learning, integrated threat intelligence, and anomaly detection to prioritize potential threats.",
      imageSrc: ManagedSecurityServicesImg2,
    },
    {
      heading: "DDOS Shield",
      subheading:
        "Facilitating Clients With Top-Of-The-Line DDOS Shield Solutions To Safeguard Against DDoS Attacks",
      description:
        "Cloud Shield devices a world-class strategy and approach to counter frequently occurring network and transport layer DDoS attacks. Countering these attacks with our robust methodologies not to allow malicious attacks to harm your website or applications.We protect all the layers such as network, DNS, SSL, and application. Besides, our solutions also focus on protocols including HTTP, SIP, TCP, UDP, and DNS. Whether you have a last dedicated network or distributed network capacity, we drive contemporary methods to protect you against DDoS attacks.Our strategies allow us to reduce downtime, reduce cost, and respond effectively to attacks of all natures.",
      imageSrc: ManagedSecurityServicesImg1,
    },
    {
      heading: "Identity & Access Management ",
      subheading:
        "Facilitating Clients With Top-Of-The-Line DDOS Shield Solutions To Safeguard Against DDoS Attacks",
      description:
        "Cloud Shield provides top-notch services when it comes to Identity and Access Management. Providing restrictive and unpenetrated access to different resources and services in the cloud is a particular focus for us in IAM. We follow strict protocols and guidelines to devise a plan that is perfectly compliant with regulatory requirements.With our solutions specifically in Identity and Access Management, your small, medium, or large-scale enterprise is always in a secure and monitored ecosystem. This drives more efficient and takes security to a whole new level.",
      imageSrc: ManagedSecurityServicesImg6,
    },
  ];
  return (
    <div>
      {" "}
      <CustomBanner
        bannerPrimaryHeadingOne={bannerPrimaryHeadingOne}
        bannerPrimaryHeadingTwo={bannerPrimaryHeadingTwo}
        bannerSubHeading={bannerSubHeading}
        bannerParagrapghTextOne={bannerParagrapghTextOne}
        bannerParagrapghTextTwo={bannerParagrapghTextTwo}
      />
      <ServiceOverview serviceData={serviceData} />
    </div>
  );
};

export default ManagedSecurityServicePage;
