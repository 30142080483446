import React from "react";
import CustomBanner from "../components/CustomBanner";
import ServiceOverview from "../components/ServiceOverview";
import EndpointSecurityServicesImg1 from "../assets/images/EndpointSecurityServicesPage/Image 1.svg";
import EndpointSecurityServicesImg2 from "../assets/images/EndpointSecurityServicesPage/Image 2.svg";
import EndpointSecurityServicesImg3 from "../assets/images/EndpointSecurityServicesPage/Image 3.svg";
import EndpointSecurityServicesImg4 from "../assets/images/EndpointSecurityServicesPage/Image 4.svg";
import EndpointSecurityServicesImg5 from "../assets/images/EndpointSecurityServicesPage/Image 5.svg";
import EndpointSecurityServicesImg6 from "../assets/images/EndpointSecurityServicesPage/Image 6.svg";
import EndpointSecurityServicesImg7 from "../assets/images/EndpointSecurityServicesPage/Image 7.svg";
import EndpointSecurityServicesImg8 from "../assets/images/EndpointSecurityServicesPage/Image 8.svg";
const EndpointSecurityServicesPage = () => {
  const bannerPrimaryHeadingOne = "Endpoint";
  const bannerPrimaryHeadingTwo = "Security";
  const bannerSubHeading = "Services";

  const bannerParagrapghTextOne =
    "When it comes to Endpoint Security Services, Cloud Shield provides top-notch security entry points or endpoints of user-operated devices such as desktops, laptops, or even mobile devices. We aim to provide services that wouldn't allow us to exploit any malicious actors and campaigns. Our strategies help your enterprise live in a completely safe environment in potential threats situations";
  const serviceData = [
    {
      heading: "Primary Functions",
      subheading:
        "Monitor The Potential Threats & Suspicious Activities To Counter",
      description:
        "Cloud Shield has set a plan to monitor and gather all the information on the endpoints. This data could possibly be a way to pose a threat or breach. But not to worry, as we being a quality security services provider, devise patterns that eliminate or contain the threat effectively. Our analysis tools help you identify suspicious activities and create a counter plan to overcome the danger.",
      imageSrc: EndpointSecurityServicesImg1,
    },
    {
      heading: "Exploit Prevention",
      subheading: "Stay Ahead And Block Any Attempt To Exploitation",
      description:
        "Cloud Shield’s Exploit Prevention helps a partner organization to create a restrictive approach that doesn't allow attackers to cross or authenticate themselves on the blocked or unallowed areas. Our team of experts carefully counter all the secretive techniques to get access to the prohibited areas, spread malware, escape detention, and steal credentials.",
      imageSrc: EndpointSecurityServicesImg2,
    },
    {
      heading: "Anti – Ransomware",
      subheading: "Prevent Ransomware Attacks Of All Nature & Size",
      description:
        "Cloud Shield's Anti-Ransomware solutions are well-designed to train, implement, and world-class practice solutions in any organization that help them prevent or counter any ransomware attack. Our strategy and plan retaliate against all sorts of locks or encrypts to protect our clients’ data and prolific systems.",
      imageSrc: EndpointSecurityServicesImg3,
    },
    {
      heading: "Deep Learning Technology",
      subheading: "Gain The Perfect Insights",
      description:
        "Cloud Shield’s Deep Learning Technology is basically a category of machine learning and artificial intelligence in the same way human being gains knowledge about certain things. Unlike the traditional learning algorithms, we go above and beyond by bringing a stacked hierarchy for increasing abstraction and complexity.",
      imageSrc: EndpointSecurityServicesImg4,
    },
    {
      heading: "Managed Threat Response",
      subheading: "Take Result-Oriented Target Actions",
      description:
        "Cloud Shield team to manage and respond to threat is a high-performing team of threat detectors and experts who bring in targeted actions on behalf of your company as a sole representation. This action helps us neutralize the most sophisticated threats effectively",
      imageSrc: EndpointSecurityServicesImg5,
    },
    {
      heading: "Enterprise Endpoint Security Services",
      subheading: "Get The Fully Secured Endpoints & Entry Points",
      description:
        "Cloud Shield’s drive is to implement secure endpoints and entry points so that your personalized devices like mobile phones, desktop computers, and laptops are safe from being breached. Here we strive to eliminate all the malicious parties to interfere in the online campaigns. This helps us craft a strategy to help our clients live stressless in the cloud from all sorts of cybersecurity threats.",
      imageSrc: EndpointSecurityServicesImg6,
    },
    {
      heading: "Why Endpoint Security",
      subheading: "Understanding Why It Is Important Is Crucial",
      description:
        "For an enterprise in its cybersecurity execution, the essential part is to bring an endpoint protection platform into practice. There are plenty of reasons that make it a matter of pivotal concern; the first is it gives protection of data which is the most crucial key for any organization. Any loss or misuse of that data can lead to the collapse of your entire business. It also pushes your business to the brink of being breach. This is where Cloud Shield plays a vital role in turning the odds in your favor by granting you a completely safe zone.",
      imageSrc: EndpointSecurityServicesImg7,
    },
    {
      heading: "Endpoint Detection & Response",
      subheading: "Monitor the Entire Database From The Top",
      description:
        "Cloud Shield's Endpoint Detection & Response is a tool that we use to monitor endpoint and network events. It is done by recording the information in a central database where further analysis, determination, investigation, detection, alerting, and reporting takes place in an effective procedure-wise manner. Moving forward, we apply actionable intelligence and behavioral analysis to stop an incident from being a dreadful and regretful breach for any business.",
      imageSrc: EndpointSecurityServicesImg8,
    },
  ];
  return (
    <div>
      {" "}
      <CustomBanner
        bannerPrimaryHeadingOne={bannerPrimaryHeadingOne}
        bannerPrimaryHeadingTwo={bannerPrimaryHeadingTwo}
        bannerSubHeading={bannerSubHeading}
        bannerParagrapghTextOne={bannerParagrapghTextOne}
      />
      <ServiceOverview serviceData={serviceData} />
    </div>
  );
};

export default EndpointSecurityServicesPage;
