import React from "react";
import CustomBanner from "../components/CustomBanner";
import { colors } from "../global/Colors";

const PrivacyPolicyPage = () => {
  const bannerPrimaryHeadingOne = "Privacy ";
  const bannerSubHeading = "Policy";
  const bannerParagrapghTextOne =
    "At Cloud Shield, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you visit or interact with our website. By using our website, you agree to the terms of this Privacy Policy.";
  const privacyContent = [
    {
      heading: "Information We Collect",
      description: (
        <>
          We do not collect personal information unless you voluntarily provide
          it. This may occur when you contact us through email, subscribe to
          newsletters, or request assistance. The personal information you may
          provide includes your name, email address, and any other contact
          information necessary for communication. In addition to personal
          information, we may also collect non-personal information
          automatically when you visit our website. This can include details
          such as your IP address, browser type, device type, the pages you
          visited, and how long you spent on each page. Information about your
          referral source, such as the website or search engine that directed
          you to Cloud Shield, may also be gathered. This data is collected to
          help us improve the performance and user experience of our website.
        </>
      ),
    },

    {
      heading: "Use of Collected Information",
      description: (
        <>
          The personal information you provide is used to respond to your
          inquiries, provide support, and send updates or newsletters if you
          have opted to receive them. We also use this information to enhance
          our services based on your feedback. The non-personal information we
          collect is used to analyze website traffic and user behavior, enhance
          website functionality, and resolve technical issues that may arise.
          This information helps us improve the overall experience for our
          users.
        </>
      ),
    },

    {
      heading: "Cookies",
      description: (
        <>
          Our website may use cookies to improve your browsing experience.
          Cookies are small data files stored on your device, enabling us to
          understand how users interact with our website. This helps improve its
          functionality and user experience. You can disable cookies through
          your browser settings; however, please note that certain features of
          the website may not function correctly if cookies are disabled.
        </>
      ),
    },

    {
      heading: "Data Security",
      description: (
        <>
          We are committed to protecting your information and employ reasonable
          security measures to prevent unauthorized access, alteration, or
          destruction of your data. However, as no method of transmitting data
          over the internet or electronic storage is entirely secure, we cannot
          guarantee absolute security. You acknowledge that you provide personal
          information at your own risk.
        </>
      ),
    },

    {
      heading: "Third-Party Links",
      description: (
        <>
          The Cloud Shield website may contain links to third-party websites or
          services that are not operated by us. We are not responsible for the
          privacy practices or content of these external sites. We encourage you
          to review the privacy policies of any third-party websites you visit
          to understand how they handle your data.
        </>
      ),
    },

    {
      heading: "Data Retention",
      description: (
        <>
          We retain your personal information only for as long as necessary to
          fulfill the purposes for which it was collected or as required by law.
          Once the information is no longer needed, it will be securely deleted
          or anonymized in accordance with applicable regulations.
        </>
      ),
    },

    {
      heading: "Your Rights",
      description: (
        <>
          You have the right to request access to any personal information we
          have collected about you. You may also request that we update or
          correct any inaccuracies in your information, or that we delete your
          personal information from our records. To exercise any of these
          rights, please contact us via the details provided below.
        </>
      ),
    },

    {
      heading: "Changes to This Privacy Policy",
      description: (
        <>
          We reserve the right to update this Privacy Policy from time to time.
          Any changes will be posted on this page along with an updated revision
          date. By continuing to use our website after any changes, you agree to
          the updated terms of this policy.
        </>
      ),
    },

    {
      heading: "Contact Us",
      description: (
        <>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please feel free to contact us at hello@sixlogs.com.
        </>
      ),
    },
  ];
  return (
    <>
      <CustomBanner
        bannerPrimaryHeadingOne={bannerPrimaryHeadingOne}
        bannerSubHeading={bannerSubHeading}
        bannerParagrapghTextOne={bannerParagrapghTextOne}
      />
      <div
        className="privacy-policy"
        style={{ backgroundColor: colors.charcoalblue }}
      >
        <div className="container py-5 px-4">
          <div className="row justify-content-center">
            <div className="col-md-10">
              {privacyContent?.map((x, index) => {
                return (
                  <div
                    key={index}
                    className={`text-white ${
                      index !== privacyContent.length - 1 ? "mb-5" : ""
                    }`}
                  >
                    <h2
                      className="text-theme-light font-face-Roboto-Bold"
                      data-aos="zoom-in"
                    >
                      {x.heading}
                    </h2>
                    <p
                      className="m-0 py-2 text-justify"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    >
                      {x.description}
                    </p>

                    {x.description2 && (
                      <p
                        className="m-0 py-2 font-face-Roboto-Regular text-justify"
                        data-aos="zoom-in"
                        data-aos-duration="2100"
                      >
                        {x.description2}
                      </p>
                    )}
                    {x.description3 && (
                      <p
                        className="m-0 py-2 font-face-Roboto-Regular text-justify"
                        data-aos="zoom-in"
                        data-aos-duration="2200"
                      >
                        {x.description3}
                      </p>
                    )}

                    {x.description4 && (
                      <p
                        className="m-0 py-2 font-face-Roboto-Regular text-justify"
                        data-aos="zoom-in"
                        data-aos-duration="2300"
                      >
                        {x.description4}
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
