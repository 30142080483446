import React, { useEffect } from "react";
import "./InfoSection.css";
import CustomButton from "./CustomButton";
import { colors } from "../global/Colors";
import Aos from "aos";
import "aos/dist/aos.css";
import { Typewriter } from "react-simple-typewriter";

const InfoSection = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  return (
    <div className="InfoSectionStyle">
      <div className="container py-3">
        <div className="row">
          <div class="d-flex justify-content-between mt-5 order-2 order-md-1">
            <h2 className="font-face-Raleway-Bold">
              <Typewriter
                words={[props?.infoHeading]}
                loop={0}
                cursor
                cursorStyle="!"
                typeSpeed={70}
                deleteSpeed={90}
                delaySpeed={2000}
              />
              {/* {props?.infoHeading} */}
            </h2>
            {props?.showButton && (
              <div className="flex-end">
                <CustomButton
                  btnText={props?.buttonText}
                  pagePath={"/contact-us/"}
                />
              </div>
            )}
          </div>

          <p
            style={{ color: colors.lightsilver }}
            className="font-face-Roboto-Regular mt-2 order-2 order-md-1 text-justify"
            data-aos="zoom-in"
            data-aos-duration="1900"
          >
            {props?.infoParaOne}
            <br></br>
            {props?.infoParaTwo}
          </p>
          <div
            className={`${
              props?.OurStrategies ? "col-md-12" : "col-md-6"
            } text-white d-flex flex-column justify-content-start order-2 order-md-1`}
          >
            <div className="mt-3 ms-3">
              <ul className="font-face-Roboto-Regular">
                {props?.infoListData.map((x, index) => (
                  <li key={index} data-aos="zoom-in" data-aos-duration="2000">
                    {x}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {props?.OurStrategies ? null : (
            <div className="col-md-6 d-flex flex-column justify-content-center align-items-center order-1 order-md-2">
              <img className="img-fluid" src={props?.Image} alt="img" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
