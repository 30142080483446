import React from "react";
import ContactUsForm from "../components/ContactUsForm";
// import ThankYouModal from "../components/ThankYouModal";

const ContactUsPage = () => {
  return (
    <>
      <ContactUsForm />
      {/* <ThankYouModal /> */}
    </>
  );
};

export default ContactUsPage;
