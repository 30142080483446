import React from "react";
import CustomBanner from "../components/CustomBanner";
import ServiceOverview from "../components/ServiceOverview";
import MalwareAnalysisPageImg1 from "../assets/images/MalwareAnalysisPage/Image 1.svg";
import MalwareAnalysisPageImg2 from "../assets/images/MalwareAnalysisPage/Image 2.svg";
import MalwareAnalysisPageImg3 from "../assets/images/MalwareAnalysisPage/Image 3.svg";

const MalwareAnalysisPage = () => {
  const bannerPrimaryHeadingOne = "Malware";
  const bannerSubHeading = "Analysis";

  const bannerParagrapghTextOne =
    "We live in a tech-driven world, where viruses and malware have emerged themselves with changing conditions. In these high-competitive yet challenging times, Cloud Shield has devised the most sophisticated analysis pattern to uncover today's evasive and advanced malware. This has elevated us to the top amongst our clients from various industries.";
  const serviceData = [
    {
      heading: "Memory Analysis",
      subheading: "Deep Memory Analysis Results",
      description:
        "Cloud Shield conducts an in-depth analysis of the memory of all your infrastructure to check the infected parts. It helps the team to understand the areas of your systems that have artifacts. Here all the memory analysis helps identify the malicious code and potential breaches trying to disguise themselves from being eliminated, just like rootkits.",
      imageSrc: MalwareAnalysisPageImg1,
    },
    {
      heading: "Code Analysis",
      subheading: "Simplified reverse-engineered Method",
      description:
        "At Cloud Shield, we conduct an in-depth analysis of your information technology infrastructure. Our teams make sure that no issue or vulnerability goes unnoticed. Common web security mistakes that weaken your infrastructures include injection flaws, broken authentication, cross-site scripting, insecure direct object references, secure misconfiguration, sensitive data exposure, etc. We fix security issues of all natures to enhance product security structure to the next level.  ",
      imageSrc: MalwareAnalysisPageImg2,
    },
    {
      heading: "Comprehensive Visibility On Network Activity",
      subheading: "Be Vigilant On Network Activities",
      description:
        "Cloud Shield's comprehensive visibility on network activity helps the team to craft state-of-the-art methods to best detect what is necessary for your corporation. Here we examine the malware specimen's interactions with all of its elements in the environment, for example, the file system, the registry, the network, and other processes and OS components. Moreover, our malware investigator or threat detector notices the changes happening in the behavior of your system. After that, the team executes strategies to evoke new and enhanced characteristics.",
      imageSrc: MalwareAnalysisPageImg3,
    },
  ];
  return (
    <div>
      <CustomBanner
        bannerPrimaryHeadingOne={bannerPrimaryHeadingOne}
        bannerSubHeading={bannerSubHeading}
        bannerParagrapghTextOne={bannerParagrapghTextOne}
      />
      <ServiceOverview serviceData={serviceData} />
    </div>
  );
};

export default MalwareAnalysisPage;
