import React, { useEffect } from "react";
import "./OurCultureAndValues.css";
import { colors } from "../global/Colors";
import OurCultureImg from "../assets/images/OurCulture.svg";
import OurValuesImg from "../assets/images/ourValues.svg";
import Aos from "aos";
import "aos/dist/aos.css";

const OurCultureAndValues = () => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  const serviceData = [
    {
      heading: "Our Culture",
      description:
        "Following a transparent structure, where our client's success matters the most, we strive to change digital communities by offering secured services. We have a clear set of principles that allow us to assist our partners in growing incredibly. In addition, we believe in working more than a paycheck. Our professionals have an always learning attitude to help our partners succeed, learn, and achieve their values.",
      imageSrc: OurCultureImg,
    },
    {
      heading: "Our Values",
      description:
        "We are a team of skilled specialists who value executing perfection, completeness, concreteness, and clarity at the same time. Meeting business needs of all sizes and industries, we have set security resolutions that speak for themselves. In addition, we believe in detailing and help to excel.",
      descriptiontwo:
        "What's more, our well-integrated system allows everyone to contribute to talk and share ideas. This group thinking and brainstorming helped us devise the finest solutions for our partners.",
      imageSrc: OurValuesImg,
    },
  ];
  return (
    <>
      {serviceData.map((x, index) => {
        const isEvenRow = index % 2 === 0;

        const isNotLastItem = index !== serviceData?.length - 1;
        return (
          <div
            style={{ backgroundColor: colors.darkcharcoal }}
            className="ourcultureandvalues"
          >
            <div className="container py-5 text-white">
              <div
                key={index}
                className={`row ${isEvenRow ? "" : "flex-row-reverse"} ${
                  isNotLastItem ? "mb-1" : ""
                }`}
              >
                <div className="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
                  <h3
                    className="font-face-Raleway-Bold mt-4"
                    data-aos="zoom-in"
                    data-aos-duration="1900"
                  >
                    {x.heading}
                  </h3>
                  <h5
                    className="mb-3 font-face-Raleway-Bold"
                    data-aos="zoom-in"
                    data-aos-duration="2000"
                  >
                    {x.subheading}
                  </h5>
                  <p
                    className="font-face-Roboto-Regular text-justify"
                    data-aos="zoom-in"
                    data-aos-duration="1900"
                  >
                    {x.description} <br></br>
                    <span data-aos="zoom-in" data-aos-duration="2000">
                      {x.descriptiontwo}
                    </span>
                  </p>
                </div>

                <div className="col-md-6 d-flex flex-column justify-content-center align-items-center pt-3 pt-xl-0 pt-lg-0 pt-md-0 order-1 order-md-2">
                  <img
                    className="img-fluid"
                    src={x.imageSrc}
                    alt={`servicesImage ${index}`}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default OurCultureAndValues;
