import React from "react";
import CustomBanner from "../components/CustomBanner.js";
import CloudSecuritySolutions from "../components/CloudSecuritySolutions.js";
import OurStorySection from "../components/OurStorySection.js";
import OurCultureAndValues from "../components/OurCultureAndValues.js";
import StrategicPartnershipSection from "../components/StrategicPartnershipSection.js";
import InfoSection from "../components/InfoSection.js";
import Image from "../assets/images/InfoSectionImg.svg";

const AboutUsPage = () => {
  const bannerPrimaryHeadingOne = "About";
  const bannerPrimaryHeadingTwo = "Us";
  const bannerParagrapghTextOne =
    "Cloud Shield is a world-class Managed Security Service Provider (MSSP) founded in 2017 with an initiative to take digital security to a whole new level.";

  const bannerParagrapghTextTwo =
    "At Cloud Shield, we strive to meet the ever-expanding demand for digital security. Driving to go above and beyond, we create a rock-solid and impenetrable security model that help you achieve your business objectives in a secure ecosystem.";

  const bannerParagrapghTextThree =
    "With years of precision and blended experience, we have perfected managing the critical infrastructure of organizations of all sizes. Our team of certified professionals offers premium class security services that help customers unlock their true digital potential.";

  const infoHeading = "What Sets Us Apart From Others";

  const infoParaOne =
    "Cloud Shield is an organization of managed security service providers with which you don't need to acquire additional cyber security products from the market. We provide all-in-one packages to set your business security to another level. for this, we use the right technology, result-oriented strategies, and high-tech capabilities to enhance your cybersecurity status.";

  const infoParaTwo =
    "At our company, we are a team of committed individuals who have the expertise to provide effective security mitigation following our skills and specialized areas. Our professional team comprises of the following";

  const infoListData = [
    "Information Security Specialists",
    "Application Security / Pen Tester",
    "Database Security Analyst",
    "Threat Scientist",
    "Digital Forensic Specialist",
    "SOC Engineer",
    "SOC Analyst",
  ];

  const infoHeadingOurStrategies = "Our Strategies";

  const infoParaOneOurStrategies =
    "Cloud Shield has a solid mission to empower enterprises and ventures to efficiently and securely utilize the best of technology. Our services aim to provide full-scale, comprehensive digital security using a holistic approach to help you reach your optimum level in no time.When a cohesive team of industry professionals surrounds you, you get the strongest guard for your website and digital solutions. Our cutting-edge strategies create an actionable drive for your venture no matter what your company provides and assists. Here's what best describes our strategy;";

  const infoListDataOurStrategies = [
    "We use a state-of-the-art skillset to construct an unparalleled analytic solution.",
    "We conduct profound R&D methodology to reach perfection.",
    "Our comprehensive approach in big-data analytics helps us provide quality to business streams of all sizes.",
  ];
  return (
    <div>
      <CustomBanner
        bannerPrimaryHeadingOne={bannerPrimaryHeadingOne}
        bannerPrimaryHeadingTwo={bannerPrimaryHeadingTwo}
        bannerParagrapghTextOne={bannerParagrapghTextOne}
        bannerParagrapghTextTwo={bannerParagrapghTextTwo}
        bannerParagrapghTextThree={bannerParagrapghTextThree}
      />
      <CloudSecuritySolutions />
      <OurStorySection />
      <InfoSection
        infoHeading={infoHeading}
        infoParaOne={infoParaOne}
        infoParaTwo={infoParaTwo}
        infoListData={infoListData}
        Image={Image}
        showButton={true}
        buttonText="Get a Quote"
      />
      <OurCultureAndValues />
      <InfoSection
        infoHeading={infoHeadingOurStrategies}
        infoParaOne={infoParaOneOurStrategies}
        infoListData={infoListDataOurStrategies}
        Image={Image}
        showButton={true}
        OurStrategies={true}
        buttonText="Get a Quote"
      />
      <StrategicPartnershipSection />
    </div>
  );
};

export default AboutUsPage;
