import React from "react";
import CustomBanner from "../components/CustomBanner";
import ServiceOverview from "../components/ServiceOverview";
import SecurityEngineeringPageImg1 from "../assets/images/SecurityEngineeringPage/Image 1.svg";
import SecurityEngineeringPageImg2 from "../assets/images/SecurityEngineeringPage/Image 2.svg";
import SecurityEngineeringPageImg3 from "../assets/images/SecurityEngineeringPage/Image 3.svg";
import SecurityEngineeringPageImg4 from "../assets/images/SecurityEngineeringPage/Image 4.svg";
import SecurityEngineeringPageImg5 from "../assets/images/SecurityEngineeringPage/Image 5.svg";
import SecurityEngineeringPageImg6 from "../assets/images/SecurityEngineeringPage/Image 6.svg";

const SecurityEngineeringPage = () => {
  const bannerPrimaryHeadingOne = "Security ";
  const bannerSubHeading = "Engineering";

  const bannerParagrapghTextOne =
    "Cloud Shield’s services in security engineering is an in-depth process of incorporating security control into your information infrastructure. This security engineering is done to execute high-level integral security to enhance the system's operational capabilities.";

  const bannerParagrapghTextTwo =
    "This service of Cloud Shield will help you design and build a secure solution that encompasses software development, testing, and integration with all the other security Original Equipment Manufacturers (OEMs). Here, using the top-of-the-line services, we elevate the power to augment in-house capabilities to bring innovation in prototypes. This will ultimately lead to stay ahead in a competitive market.";
  const serviceData = [
    {
      heading: "Design Products",
      subheading: "Get The Enhanced Security On Infrastructure",
      description:
        "Cloud Shield’s continuous strive to deliver impeccable services in security engineering has enabled to design safe and secure company’s infrastructure. In this security model, we drive secure product management, conduct all the requirement formalization, and design, and architecture services that set your enterprise to a whole new level.",
      imageSrc: SecurityEngineeringPageImg1,
    },
    {
      heading: "Fix Product Issues",
      subheading: "Continuous Struggle To Fix Issues",
      description:
        "At Cloud Shield, we conduct an in-depth analysis of your information technology infrastructure. Our teams make sure that no issue or vulnerability goes unnoticed. Common web security mistakes that weaken your infrastructures include injection flaws, broken authentication, cross-site scripting, insecure direct object references, secure misconfiguration, sensitive data exposure, etc. We fix security issues of all natures to enhance product security structure to the next level.  ",
      imageSrc: SecurityEngineeringPageImg2,
    },
    {
      heading: "Design and Develop Applications",
      subheading: "Take Your Security Level To A Whole New Level",
      description:
        "Cloud Shield is a leading cybersecurity service provider with expertise in providing product interface extensions and inter-product integrations. Here our team of expert security developers and designers use their knowledge, skillset, and experience to design, develop, and execute applications to provide dashboards reports, user actions, and data exchange between security products to create a safe and secure enterprise web presence",
      imageSrc: SecurityEngineeringPageImg3,
    },
    {
      heading: "Build Products",
      subheading: "Build Secure Products To Elevate Performance",
      description:
        "Cloud Shield works to facilitate clients with security products that allow them to extend their services in a hassle-free atmosphere. Here are strategies that involve rapid prototyping, in which the security designs have been implemented to enhance your security levels. Moreover, we also work on Minimum Viable Product (MVP) and conduct profound testing to encompass units, functions, regression, and performance.",
      imageSrc: SecurityEngineeringPageImg4,
    },
    {
      heading: "Enhance Products",
      subheading: "Improvise Security Levels",
      description:
        "At Cloud Shield, we conduct top-class feature management to build and rollout incremental improvements. These improvisations not only add to better security existence but also harness the power of your business. In addition, our team collaborates and works on the product extension by adding plugins that improve security standards to a whole new level.",
      imageSrc: SecurityEngineeringPageImg5,
    },
    {
      heading: "Exchange Data Between Products",
      subheading: "Exchange Data Within Unparalleled Ecosystem",
      description:
        "Cloud Shield holds a strong ground for providing its clients with safe products to exchange data with internal or external partners. Here, the event normalization goes by using parsers and transformations for event data exchange across products. In addition, we work on the design and improving build services to integrate multi-vendor applications and products.",
      imageSrc: SecurityEngineeringPageImg6,
    },
  ];
  return (
    <div>
      <CustomBanner
        bannerPrimaryHeadingOne={bannerPrimaryHeadingOne}
        bannerSubHeading={bannerSubHeading}
        bannerParagrapghTextOne={bannerParagrapghTextOne}
        bannerParagrapghTextTwo={bannerParagrapghTextTwo}
      />
      <ServiceOverview serviceData={serviceData} />
    </div>
  );
};

export default SecurityEngineeringPage;
