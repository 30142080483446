import React, { useEffect } from "react";
import "./StrategicPartnershipSection.css";
import StrategicPartnershipSectionImg from "../assets/images/StrategicPartnershipSection1.svg";
import SaleforceIcon from "../assets/images/SaleforceIcon.svg";
import AWSIcon from "../assets/images/AWSIcon.svg";
import MicrosoftServiceIcon from "../assets/images/MicrosoftServiceIcon.svg";
import GoogleCloudIcon from "../assets/images/GoogleCloudIcon.svg";
import RapidComputeIcon from "../assets/images/RapidComputeIcon.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Typewriter } from "react-simple-typewriter";
import Aos from "aos";
import "aos/dist/aos.css";

const StrategicPartnershipSection = () => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  const data = [
    {
      icon: AWSIcon,
    },
    {
      icon: MicrosoftServiceIcon,
    },
    {
      icon: SaleforceIcon,
    },
    {
      icon: RapidComputeIcon,
    },
    {
      icon: GoogleCloudIcon,
    },
  ];
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Default for larger screens
    slidesToScroll: 1,
    dots: false,
    centerMode: false, // Turn off centering for larger screens

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Show only 1 image
          slidesToScroll: 1, // Scroll one image at a time
          infinite: true,
          dots: false,
          centerMode: true, // Ensure the active image is centered
          centerPadding: "0px", // No extra padding so no partial images
          initialSlide: 0, // Start at the first slide
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: true, // Center the active image
          centerPadding: "0px", // Ensure no image is cut off or shown partially
          initialSlide: 0, // Start from the first slide
        },
      },
    ],
  };
  return (
    <div className="StrategicPartnershipSectionStyle">
      <div className="container py-3">
        <div className="row">
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center p-4">
            <img className="img-fluid" src={StrategicPartnershipSectionImg} />
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center p-3">
            <h1 className="text-white text-center text-lg-start text-md-start mt-3 font-face-Raleway-Bold">
              <Typewriter
                words={["Strategic Partnership"]}
                loop={0}
                cursor
                typeSpeed={70}
                deleteSpeed={90}
                delaySpeed={2000}
              />
            </h1>
            <p
              className="text-lg-start text-md-start mb-5 font-face-Roboto-Regular text-justify"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              Cloud Shield has partnered with industry leaders to provide a
              secured shield to scale in this cutting-edge age of technology. We
              keenly understand the role of strategic partnerships in scaling
              and uplifting your business.
            </p>

            <div className="row">
              {data?.map((x, index) => {
                return (
                  <div
                    className="abc col-lg-4 col-md-6 col-sm-12 text-center mb-5"
                    key={index}
                  >
                    <img src={x.icon} />
                  </div>
                );
              })}
              <div className="sliderStyle">
                <Slider {...settings}>
                  {data?.map((x, index) => (
                    <div>
                      <div className="carousel-container">
                        <img
                          className="img-fluid"
                          src={x.icon}
                          alt={`img-${index}`}
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrategicPartnershipSection;
