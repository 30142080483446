import React, { useEffect } from "react";
import { colors } from "../global/Colors";
import "./OurStorySectionStyle.css";
import Aos from "aos";
import "aos/dist/aos.css";

const OurStorySection = () => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  return (
    <div className="OurStorySectionStyle m-0">
      <div className="container text-center py-5">
        <div className="row">
          <div className="col-md-12 text-white d-flex flex-column justify-content-center align-item-center">
            <h1
              className="mb-4 font-face-Raleway-Regular"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <span
                style={{ color: colors.green }}
                className="font-face-Raleway-Bold"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                Our
              </span>{" "}
              Story
            </h1>

            <p
              className="font-face-Roboto-Regular"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              Cloud Shield started with a commitment to provide our clients a
              secure online presence that ensures success. Our services are for
              unicorn startups, mid-level enterprises, and large-scale
              businesses, and government organizations to reach elevated heights
              of success in sales and revenues. We firmly believe that your
              success matters for our success. And with this idea in place, we
              have devised a proactive approach to cater to all the
              complications and disruptions and provide cost-effective
              resolutions to stand against the whopping costing.
            </p>

            <p
              className="font-face-Roboto-Regular"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              Our team of certified professionals and expert service providers
              understand the core role of services in your business. Keeping
              this in mind, we help our clients thrive in this inevitable
              menacing digital age.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStorySection;
