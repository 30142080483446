import React from "react";
import ServiceOverview from "../components/ServiceOverview";
import Image from "../assets/images/RocketImg.svg";
import InfoSection from "../components/InfoSection";
import StrategicPartnershipSection from "../components/StrategicPartnershipSection";
import CaseStudyImg2 from "../assets/images/CaseStudy Image 3.svg";
import CaseStudyImg1 from "../assets/images/CaseStudy Image 2.svg";
import CaseStudyImg3 from "../assets/images/CaseStudy Image1.svg";

const CaseStudiesPage = () => {
  const serviceData = [
    {
      heading: "Web Application Penetration Testing",
      description:
        "For a new business venture reputation is the real currency, we utilized Cloud Shield's services to steer a complete web application security assessment to detect and validate vulnerabilities based on OWASP guidelines in order to time to mitigate any avenues paving the way for hackers to compromise the [xyz – customer name] our application before go-live.",
      imageSrc: CaseStudyImg2,
      listpoints: [
        "Assessing exposed vulnerabilities in the application",
        "Confirming the vulnerability exposure with a proof of concept (POC)",
        "Submitting and presenting a technical report",
        "Re-validating vulnerabilities to ensure proper closure",
        "Providing recommendations for platform hardening",
      ],
    },
    {
      heading: "Cloud Security Managed Services",
      description:
        "We utilized Cloud Shield's managed security services for timely detection and prevention of threats that surface through the internet. We are now at a relief, as all our AWS cloud instances are continuously being monitored for any intrusions and get timely hardened by Cloud Shield's 24x7 cyber fusion engineering unit based on the latest threat intel. ",
      imageSrc: CaseStudyImg3,
      listpoints: [
        "Real-time threat detection and intrusion analysis",
        "AWS instances hardening",
        "Weekly Reporting ",
        "On-going vulnerability assessment based on latest CVEs",
        "Security Bullet-ins based on real-time threat intel",
      ],
    },
    {
      heading: "Android Mobile App Security Assessment",
      description:
        "As a startup, the security of our mobile app was the topmost priority before stepping our foot into the market.We utilized Cloud Shield's offensive security services to detect weaknesses in our financial android mobile application and detected technical gaps that might provide economic losses opportunities. The findings which were shared with us helped us solidify our venture's journey into the digital space.",
      imageSrc: CaseStudyImg1,
      listpoints: [
        "End-to-End Mobile Application Traffic Analysis",
        "Mobile app obfuscation testing, root, emulator, and tamper detection",
        "Mobile app business logic exploitation",
        "Sensitive information leakages from the app modules",
        "Application security assessment report with POCs",
        "Revalidation of remediation actions with conformance to go live",
      ],
    },
  ];

  const infoHeading = "Managed SOC Services";

  const infoParaOne =
    "We were on the lookout for a SOC partner for a long time and had been hopping here to there. We have been working with Cloud Shield for the last few months, and their cyber fusion team is a great help. The level of visibility of threats and the timely response we get is something that adds real value to our environment and continuously keeps us updated with the evolving threat landscape.";

  const infoListData = [
    "Alerting Use-cases",
    "Data enrichment via latest threat intel",
    "Incident Response and forensics services",
    "Threat Hunting",
    "Weekly Reporting",
    "Customized alerting use-cases",
    "Purple Team Exercises",
  ];
  return (
    <div>
      <div className="mt-5">
        <ServiceOverview serviceData={serviceData} show={true} />
        <InfoSection
          infoHeading={infoHeading}
          infoParaOne={infoParaOne}
          infoListData={infoListData}
          Image={Image}
        />
        <StrategicPartnershipSection />
      </div>
    </div>
  );
};

export default CaseStudiesPage;
