import React, { useEffect } from "react";
import "./CustomBanner.css";
import { colors } from "../global/Colors";
import Aos from "aos";
import "aos/dist/aos.css";

const CustomBanner = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  return (
    <div className="mt-5" style={{ backgroundColor: colors.charcoalblue }}>
      <div className="custom-banner py-5">
        <div className="container py-5">
          <div className="row mx-auto">
            <div className="col-md-6 d-flex flex-column">
              <h1
                className="banner-heading font-face-Raleway-Regular  text-theme-white"
                data-aos="zoom-in"
                data-aos-duration="1900"
              >
                <span
                  className="font-face-Raleway-Bold"
                  style={{ color: colors.green }}
                >
                  {props?.bannerPrimaryHeadingOne}
                </span>{" "}
                {props?.bannerPrimaryHeadingTwo} <br></br>
                {props?.bannerSubHeading}
              </h1>
            </div>
            <div className="banner-para col-md-6 d-flex flex-column font-face-Roboto-Regular justify-content-center align-items-end">
              <p
                data-aos="zoom-in"
                data-aos-duration="1900"
                className="text-justify"
              >
                {props?.bannerParagrapghTextOne}{" "}
              </p>

              <p
                data-aos="zoom-in"
                data-aos-duration="2000"
                className="text-justify"
              >
                {props?.bannerParagrapghTextTwo}{" "}
              </p>
              <p
                data-aos="zoom-in"
                data-aos-duration="2200"
                className="text-justify"
              >
                {props?.bannerParagrapghTextThree}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomBanner;
