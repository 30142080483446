import React from "react";
import "./CustomButton.css";
import { useNavigate } from "react-router-dom";

const CustomButton = (props) => {
  const navigate = useNavigate();
  const navigateToPage = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <button
      onClick={() => {
        if (props?.pagePath) {
          navigateToPage(props.pagePath);
        }
      }}
      class="learn-more"
    >
      <span
        class="circle"
        aria-hidden="true"
        style={{
          backgroundColor: props?.Signup ? "#c4c4c467" : undefined,
          backgroundImage: !props?.Signup
            ? "linear-gradient(to bottom right, #63c661af, #045f92)"
            : undefined,
        }}
      >
        <span class="icon arrow"></span>
      </span>
      <span class="button-text">{props?.btnText}</span>
    </button>
  );
};

export default CustomButton;
