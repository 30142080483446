import React from "react";
import CustomBanner from "../components/CustomBanner";
import ServiceOverview from "../components/ServiceOverview";
import NetworkSecurityManagmentImg1 from "../assets/images/NetworkSecurityManagment/Image 1.svg";
import NetworkSecurityManagmentImg2 from "../assets/images/NetworkSecurityManagment/Image 2.svg";
import NetworkSecurityManagmentImg3 from "../assets/images/NetworkSecurityManagment/Image 3.svg";
import NetworkSecurityManagmentImg4 from "../assets/images/NetworkSecurityManagment/Image 4.svg";
import NetworkSecurityManagmentImg5 from "../assets/images/NetworkSecurityManagment/Image 5.svg";
import NetworkSecurityManagmentImg6 from "../assets/images/NetworkSecurityManagment/Image 6.svg";

const NetworkSecurityManagementPage = () => {
  const bannerPrimaryHeadingOne = "Network";
  const bannerPrimaryHeadingTwo = "Security";
  const bannerSubHeading = "Management";

  const bannerParagrapghTextOne =
    "We have a transparent method for check Network Security Management. This provides complete visibility into all the networks and generates quality and convertible data for assets, applications, firewalls, protocols, open ports, VPNs, and security policies of all kinds. Our team of skilled professionals creates an in-depth layer to protect all the files and data that must not be beached by unauthorized systems, just like an anti-virus software that doesn’t allow unsafe actors to proceed.";
  const serviceData = [
    {
      heading: "Harden Network Devices",
      subheading: "Take Your Infrastructure Security Levels To Next-Levels",
      description:
        "Cloud Shield’s Network Hardening Devices is an excellent way to enhance and boost your infrastructure security level. We bring into practice the ways by which our client follows all the industry standards and protocols. This is where we incorporate unparalleled services in regards to security access, protecting routers, network encryption, and backing up configurations",
      imageSrc: NetworkSecurityManagmentImg1,
    },
    {
      heading: "Validate Integrity of Hardware & Software",
      subheading: "Get Rid Of Gray Market Products",
      description:
        "Cloud Shield provides you a list of the finest hardware and software products that don't allow you to breach or attack your network. Our team ensures not to procure or eliminate if any gray market product as they are a great source for introducing unsuspecting networks. We create a protocol standard for your organization that asks you to follow all the integrity checks on your devices and software to get a seamless networking platform.",
      imageSrc: NetworkSecurityManagmentImg2,
    },
    {
      heading: "Perform Out-Of-Band (OoB) Network Management",
      subheading:
        "Get The Seamless Power To Control From Anywhere In The World",
      description:
        "Cloud Shield drives Out-of-band network management is a method of controlling and managing all the actions remotely. This action also helps us manage and control all the critical information technology assets and network equipment using a secure connection through a separate interface for establishing an incredible control even in the infrastructure defects",
      imageSrc: NetworkSecurityManagmentImg3,
    },
    {
      heading: "Secure Access To Infrastructure Devices",
      subheading: "Establish A Secure Zone For Resources",
      description:
        "Cloud Shield has vast expertise in creating a secure administrative ecosystem for businesses to prevail in this competitive environment. Our devised strategy helps any enterprise adopt an approach to grant limited access to only trusted resources within the organization. For example, we follow strict protocols to implement multi-factor authentication (MFA) to access the secured data. This approach helps the organizations in controlling administrative credentials",
      imageSrc: NetworkSecurityManagmentImg4,
    },
    {
      heading: "Segment and Segregate Networks and Functions",
      subheading:
        "The Art Of Segmentation & Segregation In-Network Boost Security",
      description:
        "Cloud Shield follows strict policy when it comes to segment and segregate networks and functions for any corporation. This enhances the overall infrastructure layout of your organization. This secure infrastructure doesn't allow irrelevant and unallowed resources or intruders to gain access and exploit the valuable information within the network. Here, as a professional team, we use separate routers to create boundaries and filter broadcast traffic. These strategies help us segment the traffic and restrict the others from getting access and exploit the vulnerabilities.",
      imageSrc: NetworkSecurityManagmentImg5,
    },
    {
      heading: "Limit Unnecessary Lateral Communications",
      subheading: "Control The Safe Communication Channel",
      description:
        " Cloud Shield's work sets the standards by creating a bionetwork in which peer-to-peer communication is controlled impeccably. Often when resources are communicating with each other, there is a presumable chance that intruders or hackers can gain access to devices by creating backdoors or with the installation of certain applications. But with Cloud Shield's strategy to limit the unnecessary lateral communications, no attacker can establish a persistence position within the network by embedding anything that bypasses your security walls.",
      imageSrc: NetworkSecurityManagmentImg6,
    },
  ];
  return (
    <div>
      {" "}
      <CustomBanner
        bannerPrimaryHeadingOne={bannerPrimaryHeadingOne}
        bannerPrimaryHeadingTwo={bannerPrimaryHeadingTwo}
        bannerSubHeading={bannerSubHeading}
        bannerParagrapghTextOne={bannerParagrapghTextOne}
      />
      <ServiceOverview serviceData={serviceData} />
    </div>
  );
};

export default NetworkSecurityManagementPage;
