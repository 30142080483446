import React from "react";
import CustomBanner from "../components/CustomBanner";
import ServiceOverview from "../components/ServiceOverview";
import SecurityAwarenessProgramPageImg1 from "../assets/images/SecurityAwarenessProgramPage/Image 1.svg";
import SecurityAwarenessProgramPageImg2 from "../assets/images/SecurityAwarenessProgramPage/Image 2.svg";
import SecurityAwarenessProgramPageImg3 from "../assets/images/SecurityAwarenessProgramPage/Image 3.svg";
import SecurityAwarenessProgramPageImg4 from "../assets/images/SecurityAwarenessProgramPage/Image 4.svg";
import SecurityAwarenessProgramPageImg5 from "../assets/images/SecurityAwarenessProgramPage/Image 5.svg";

const SecurityAwarenessProgramPage = () => {
  const bannerPrimaryHeadingOne = "Security";
  const bannerPrimaryHeadingTwo = "Awareness";
  const bannerSubHeading = "Program";

  const bannerParagrapghTextOne =
    "Apart from acquiring state-of-the-art security features, infrastructure, configurations, or equipment, running awareness programs and educating your workforce or resources with what's best for the company and its objectives. This is where Cloud Shield comes to offer spectacular services in regards to the company's security enhancement. From training awareness to certifications and workshops, our team of proficient trainers conducts sessions that help your workforce best understand the policies and guidelines.";
  const serviceData = [
    {
      heading: "Highly Engaging Training Modules",
      subheading: "Training Modules Like Never Before",
      description:
        "Cloud Shield leads the industry when it comes to providing training and development sessions to the employees or resources of any enterprise. Our team of professionals offers top-notch engaging training modules to keep users entertained with brief and comprehensive video views that present securities level to viewers efficiently. This helps your organization to scale and bring productivity revenues.",
      imageSrc: SecurityAwarenessProgramPageImg1,
    },
    {
      heading: "Personalized Risk Scores",
      subheading: "Understand Risks & Implement Adequate Strategy",
      description:
        "Cloud Shield provides personalized risk scores that are assigned to each of your employees in the workforce. It helps an organization understand where the greatest risks are, what might be the strike on vulnerability, and how to avoid it. This, in general, points the direction and helps you strategize yourself with respect to the threat concentration. While at the same time, we tend to address the risk and possible solutions required to eliminate it.",
      imageSrc: SecurityAwarenessProgramPageImg2,
    },
    {
      heading: "Scalability & Efficiency",
      subheading: "Become More Efficient",
      description:
        "Cloud Shield focuses on providing small, medium, and large-scale businesses with high-class and result-driven training and workshops that improve overall employee engagement and understanding. It specifically highlights the graph presenting the initial standing of your organization and workforce when the training begins with them thereafter results and standpoints.",
      imageSrc: SecurityAwarenessProgramPageImg3,
    },
    {
      heading: "Customized Training",
      subheading: "Customized Training Meeting Your Organizations' Goals",
      description:
        "At Cloud Shield, our team of skilled trainers has years of working experience and adequate certifications to be the best in the industry. Apart from all the standard training modules that we offer basically in the initial phase, we have customized and project-based training modules in place as well. After understanding your current phase, our team then brainstorms and collaborates with the technical team to devise a plan that best suits your requirements. This strategy of customized training has been beneficial for years. And we believe it will continue to be fruitful in the future too.",
      imageSrc: SecurityAwarenessProgramPageImg4,
    },
    {
      heading: "Continual Testing",
      subheading: "Develop Testing Models To Enhance Security Levels",
      description:
        "We are a team of high-class professionals at Cloud Shield. This enables us to provide any organization a strategy to measure employee learning, development, and enhancement to gauge their sentiments about the existing security practices, techniques, and implementations. For instance, this takes us to understand how employees of organizations care or actually feel about security. Moreover, it also sheds light on how to tackle any malicious threat in any unforeseen circumstances.",
      imageSrc: SecurityAwarenessProgramPageImg5,
    },
  ];
  return (
    <div>
      <CustomBanner
        bannerPrimaryHeadingOne={bannerPrimaryHeadingOne}
        bannerPrimaryHeadingTwo={bannerPrimaryHeadingTwo}
        bannerSubHeading={bannerSubHeading}
        bannerParagrapghTextOne={bannerParagrapghTextOne}
      />
      <ServiceOverview serviceData={serviceData} />
    </div>
  );
};

export default SecurityAwarenessProgramPage;
