import React from "react";
import CustomBanner from "../components/CustomBanner";
import { colors } from "../global/Colors";
import { useNavigate } from "react-router-dom";

const TermsAndConditionsPage = () => {
  const bannerPrimaryHeadingOne = "Terms";
  const bannerPrimaryHeadingTwo = "and";
  const bannerSubHeading = "Conditions";
  const bannerParagrapghTextOne =
    "Welcome to Cloud Shield. By accessing and using our website, you agree to comply with and be bound by the following terms and conditions. Please review these terms carefully. If you do not agree to these terms, you should not use our website.";
  const privacyContent = [
    {
      heading: "Definitions",
      description: (
        <>
          In this document, "Company" refers to{" "}
          <span style={{ fontFamily: "Roboto-Bold" }}>Cloud Shield</span>,
          including its employees, directors, affiliates, and contractors.
          "Service" refers to the information, tools, and resources provided by
          <span style={{ fontFamily: "Roboto-Bold" }}> Cloud Shield </span> for
          cloud security and related services. "You" or "User" refers to any
          individual or entity using our website.
        </>
      ),
    },

    {
      heading: "Use of the Website",
      description: (
        <>
          You agree to use our website only for lawful purposes, ensuring that
          your use does not infringe on the rights of others or restrict their
          use of the site. Unauthorized use, including hacking or spreading
          malware, is strictly prohibited.
        </>
      ),
    },

    {
      heading: "Intellectual Property Rights",
      description: (
        <>
          All content available on this website, including but not limited to
          text, graphics, logos, and software, is the intellectual property of
          <span style={{ fontFamily: "Roboto-Bold" }}> Cloud Shield </span> or
          its licensors. You may not reproduce, distribute, modify, or use any
          content without prior written consent from{" "}
          <span style={{ fontFamily: "Roboto-Bold" }}>Cloud Shield</span>.
        </>
      ),
    },

    {
      heading: "Information Accuracy",
      description: (
        <>
          We strive to ensure the accuracy and currency of all information
          provided on the website. However, we do not guarantee that the content
          is free from errors, omissions, or inaccuracies.{" "}
          <span style={{ fontFamily: "Roboto-Bold" }}>Cloud Shield </span>
          reserves the right to make changes to the website’s content at any
          time without notice.
        </>
      ),
    },

    {
      heading: "Limitation of Liability",
      description: (
        <>
          <span style={{ fontFamily: "Roboto-Bold" }}>Cloud Shield</span> will
          not be liable for any direct, indirect, incidental, or consequential
          damages resulting from the use or inability to use our website,
          including but not limited to data loss, unauthorized access, or
          technical issues. The website and its content are provided on an
          "as-is" and "as available" basis. We do not guarantee that the website
          will be error-free or uninterrupted.
        </>
      ),
    },

    {
      heading: "Third-Party Links",
      description: (
        <>
          Our website may contain links to third-party websites or services not
          owned or controlled by{" "}
          <span style={{ fontFamily: "Roboto-Bold" }}>Cloud Shield</span>. We
          have no control over, and assume no responsibility for, the content,
          privacy policies, or practices of any third-party websites or
          services.
        </>
      ),
    },

    {
      heading: "Privacy Policy",
      description: (
        <>
          Your use of our website is also governed by our
          <span style={{ fontFamily: "Roboto-Bold" }}> Privacy Policy</span>,
          which explains how we collect, use, and protect your personal data. By
          using our website, you consent to the data practices outlined in our
          Privacy Policy.
        </>
      ),
    },

    {
      heading: "Changes to Terms",
      description: (
        <>
          We may revise these{" "}
          <span style={{ fontFamily: "Roboto-Bold" }}>
            Terms and Conditions
          </span>{" "}
          periodically. Changes will be posted on this page, and your continued
          use of the website after any updates constitutes your acceptance of
          the revised terms.
        </>
      ),
    },
    {
      heading: "Contact Us",
      description: (
        <>
          If you have any questions or concerns about these{" "}
          <span style={{ fontFamily: "Roboto-Bold" }}>
            Terms and Conditions
          </span>
          , please contact us at Hello@sixlogs.com.
        </>
      ),
    },
  ];
  return (
    <>
      <CustomBanner
        bannerPrimaryHeadingOne={bannerPrimaryHeadingOne}
        bannerPrimaryHeadingTwo={bannerPrimaryHeadingTwo}
        bannerSubHeading={bannerSubHeading}
        bannerParagrapghTextOne={bannerParagrapghTextOne}
      />
      <div
        className="privacy-policy"
        style={{ backgroundColor: colors.charcoalblue }}
      >
        <div className="container py-5 px-4">
          <div className="row justify-content-center">
            <div className="col-md-10">
              {privacyContent?.map((x, index) => {
                return (
                  <div
                    key={index}
                    className={`text-white ${
                      index !== privacyContent.length - 1 ? "mb-5" : ""
                    }`}
                  >
                    <h2
                      className="text-theme-light font-face-Roboto-Bold"
                      data-aos="zoom-in"
                    >
                      {x.heading}
                    </h2>
                    <p
                      className="m-0 py-2 text-justify"
                      data-aos="zoom-in"
                      data-aos-duration="2000"
                    >
                      {x.description}
                    </p>

                    {x.description2 && (
                      <p
                        className="m-0 py-2 font-face-Roboto-Regular text-justify"
                        data-aos="zoom-in"
                        data-aos-duration="2100"
                      >
                        {x.description2}
                      </p>
                    )}
                    {x.description3 && (
                      <p
                        className="m-0 py-2 font-face-Roboto-Regular text-justify"
                        data-aos="zoom-in"
                        data-aos-duration="2200"
                      >
                        {x.description3}
                      </p>
                    )}

                    {x.description4 && (
                      <p
                        className="m-0 py-2 font-face-Roboto-Regular text-justify"
                        data-aos="zoom-in"
                        data-aos-duration="2300"
                      >
                        {x.description4}
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditionsPage;
