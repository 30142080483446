import React, { useEffect } from "react";
import "./CloudSecuritySolutions.css";
import Image from "../assets/images/CloudSecuritySolutionsImg.svg";
import Aos from "aos";
import "aos/dist/aos.css";

const CloudSecuritySolutions = () => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  return (
    <div className="CloudSecuritySolutionsStyle">
      <div className="container py-3">
        <div className="row">
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <img className="img-fluid" src={Image} alt="img" />
          </div>
          <div className="col-md-6 text-white d-flex flex-column justify-content-center">
            <h2 className="font-face-Raleway-Bold">
              We offer a complete range of <br></br> cloud security solutions{" "}
            </h2>

            <div className="mt-4 font-face-Roboto-Regular mb-0 ms-3">
              <ul>
                <li data-aos="zoom-in" data-aos-duration="1900">
                  Managed Security Services
                </li>
                <li data-aos="zoom-in" data-aos-duration="1900">
                  Penetrating Testing
                </li>
                <li data-aos="zoom-in" data-aos-duration="2000">
                  SOC as a Service
                </li>
                <li data-aos="zoom-in" data-aos-duration="2100">
                  Security Compliance & Auditing
                </li>
                <li data-aos="zoom-in" data-aos-duration="2200">
                  Endpoint Security Services
                </li>
                <li data-aos="zoom-in" data-aos-duration="2300">
                  Network Security Management
                </li>
                <li data-aos="zoom-in" data-aos-duration="2400">
                  Security Engineering
                </li>
                <li data-aos="zoom-in" data-aos-duration="2500">
                  Security Awareness Program
                </li>
                <li data-aos="zoom-in" data-aos-duration="2600">
                  Malware Analysis
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudSecuritySolutions;
