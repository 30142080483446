import React, { useState } from "react";
import "./SubscribeFormSection.css";
import Marquee from "react-fast-marquee";
import CustomButton from "./CustomButton";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ImageFooter1 from "../assets/images/ImageFooter1.svg";
import ImageFooter2 from "../assets/images/ImageFooter2.svg";
import ImageFooter3 from "../assets/images/ImageFooter3.svg";
import ImageFooter4 from "../assets/images/ImageFooter4.svg";
import ImageFooter5 from "../assets/images/ImageFooter5.svg";
import ImageFooter6 from "../assets/images/ImageFooter6.svg";
import ThankYouModal from "./ThankYouModal";

const SubscribeFormSection = () => {
  const [modalShow, setModalShow] = useState(false);

  const [email, setEmail] = useState("");

  const data = [
    {
      icon: ImageFooter1,
    },
    {
      icon: ImageFooter2,
    },
    {
      icon: ImageFooter3,
    },
    {
      icon: ImageFooter4,
    },
    {
      icon: ImageFooter5,
    },
    {
      icon: ImageFooter6,
    },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Default for larger screens
    slidesToScroll: 1,
    dots: false,
    centerMode: false, // Turn off centering for larger screens
    autoplay: true,
    autoplaySpeed: 3000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Show only 1 image
          slidesToScroll: 1, // Scroll one image at a time
          infinite: true,
          dots: false,
          centerMode: true, // Ensure the active image is centered
          centerPadding: "0px", // No extra padding so no partial images
          initialSlide: 0, // Start at the first slide
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: true, // Center the active image
          centerPadding: "0px", // Ensure no image is cut off or shown partially
          initialSlide: 0, // Start from the first slide
        },
      },
    ],
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      alert("Email is required");
      return;
    }

    try {
      const response = await fetch(
        "https://cloudshield-backend.vercel.app/api/email/V1/subscribeToNewsletter",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userEmail: email }),
        }
      );

      const result = await response.json();

      if (response.status === 201) {
        // alert(result.msg);
        document.getElementById("email").value = "";
        setEmail("");
        setModalShow(true);
      } else {
        alert(result.error || "An unexpected error occurred");
      }
    } catch (error) {
      console.error("Error details:", error);
      alert("An error occurred while sending the confirmation email.");
    }
  };

  return (
    <div className="SubscribeFormSection">
      <div className="container">
        {/* <div className="img-grid mb-5"> */}
        <Marquee direction="right" speed="35">
          {data.map((item, index) => (
            <img
              key={index}
              src={item.icon}
              alt={`img-${index}`}
              className="img-fluidd px-3 mb-5 mt-2"
            />
          ))}
        </Marquee>
        {/* </div> */}
        {/* 
        <div className="sliderStyle mb-5 mt-4">
          <Slider {...settings}>
            {data?.map((x, index) => (
              <div>
                <div className="carousel-container">
                  <img
                    className="img-fluid"
                    src={x.icon}
                    alt={`img-${index}`}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div> */}
        <form onSubmit={handleSubmit}>
          <div
            className="bggardient mt-5"
            style={{
              backgroundImage:
                "linear-gradient(to bottom right, #63c661af, #045F92)",
              width: "100%",
              borderRadius: "10px",
            }}
          >
            <div className="row bggardient py-3 ">
              <div className="col-lg-6 col-12 ">
                <h1 className="text-white font-face-Raleway-Bold">
                  Subscribe For Newsletter
                </h1>
                <p className="text-white font-face-Roboto-Regular">
                  Subscribe to our newsletter and we will bring <br />
                  you the latest news.
                </p>
              </div>
              <div className="col-lg-6 col-12 group">
                <div class="input-group">
                  <input
                    class="input-text"
                    name="text"
                    type="text"
                    id="email"
                    placeholder="Enter Your Email Address"
                    autocomplete="off"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {/* <label class="input-text-label" for="text">
                  Type here
                </label> */}
                </div>{" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <CustomButton btnText="Subscribe" Signup={true} />
                </div>
              </div>
            </div>
          </div>
        </form>
        <ThankYouModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          heading="Thanks for subscribing to Cloud Shield!"
          paraOne="We're excited to have you on board. You'll now receive the latest updates, news, and insights about cloud security directly in your inbox. Stay tuned for valuable tips and resources that will help you stay protected in the digital world.If you have any questions or need further information, feel free to reach out to us at secure@cloudshield.ai."
        />
      </div>
    </div>
  );
};

export default SubscribeFormSection;
