import React from "react";
import HeroImage from "../components/HeroImage.js";
import AllCloudServices from "../components/AllCloudServices.js";
import StrategicPartnershipSection from "../components/StrategicPartnershipSection.js";
import CloudServicesSection from "../components/CloudServicesSection.js";
import { colors } from "../global/Colors.js";

const HomePage = () => {
  return (
    <div style={{ backgroundColor: colors.charcoalblue }}>
      <HeroImage />
      <StrategicPartnershipSection />
      <CloudServicesSection />
      <AllCloudServices />
    </div>
  );
};

export default HomePage;
