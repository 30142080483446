import React from "react";
import "./AllCloudServices.css";
import { colors } from "../global/Colors";
import ManagedSecurityServicesIcon from "../assets/images/ManagedSecurityServicesIcon.svg";
import SocServiceIcon from "../assets/images/SocServiceIcon.svg";
import NetworkSecurityManagementServiceIcon from "../assets/images/NetworkSecurityManagementServiceIcon.svg";
import EndpointSecurityServicesIcon from "../assets/images/EndpointSecurityServicesIcon.svg";
import MalwareAnalysisServiceIcon from "../assets/images/MalwareAnalysisServiceIcon.svg";
import PenetrationTestingServiceIcon from "../assets/images/PenetrationTestingServiceIcon.svg";
import SecurityEngineeringServiceIcon from "../assets/images/SecurityEngineeringServiceIcon.svg";

import SecurityAwarenessProgramServiceIcon from "../assets/images/SecurityAwarenessProgramServiceIcon.svg";
import SecurityComplianceAndAuditingServiceIcon from "../assets/images/SecurityComplianceAndAuditingServiceIcon.svg";
import { useNavigate } from "react-router-dom";

const AllCloudServices = () => {
  const navigate = useNavigate();

  const servicesData = [
    {
      image: ManagedSecurityServicesIcon,
      heading: "Managed Security Services",
      description:
        "Proactive protection and real-time monitoring of your IT infrastructure to identify and respond to security threats before they cause harm.",
      routeName: "ManagedSecurityServices",
    },
    {
      image: SocServiceIcon,
      heading: "SOC as a Service",
      description:
        "Round-the-clock monitoring and incident response services provided by our expert Security Operations Center, tailored to your unique needs.",
      routeName: "SOCService",
    },
    {
      image: PenetrationTestingServiceIcon,
      heading: "Penetration Testing",
      description:
        "Simulated cyberattacks aimed at evaluating the security of your systems and identifying vulnerabilities that malicious actors could exploit.",
      routeName: "PenetrationTesting",
    },
    {
      image: SecurityComplianceAndAuditingServiceIcon,
      heading: "Security Compliance & Auditing",
      description:
        "Comprehensive assessments ensuring your organization meets industry standards and regulations, with detailed reports and action plans for remediation.",
      routeName: "SecurityComplianceAndAuditing",
    },
    {
      image: EndpointSecurityServicesIcon,
      heading: "Endpoint Security Services",
      description:
        "Comprehensive protection for devices such as laptops, mobile phones, and workstations, preventing unauthorized access and malware infections.",
      routeName: "EndpointSecurityServices",
    },
    {
      image: NetworkSecurityManagementServiceIcon,
      heading: "Network Security Management",
      description:
        "Ensuring the security and integrity of your network through firewalls, intrusion detection systems, and traffic analysis to prevent breaches.",
      routeName: "NetworkSecurityManagement",
    },
    {
      image: SecurityEngineeringServiceIcon,
      heading: "Security Engineering",
      description:
        "Designing and implementing robust security architecture and controls to protect systems from evolving cyber threats.",
      routeName: "SecurityEngineering",
    },
    {
      image: SecurityAwarenessProgramServiceIcon,
      heading: "Security Awareness Program",
      description:
        "Empowering your employees with the knowledge and tools to recognize, avoid, and respond to cyber threats, reducing human-related security risks.",
      routeName: "SecurityAwarenessProgram",
    },
    {
      image: MalwareAnalysisServiceIcon,
      heading: "Malware Analysis",
      description:
        "n-depth examination and dissection of malicious software to understand its behavior, origin, and impact, allowing for effective mitigation.",
      routeName: "MalwareAnalysis",
    },
  ];

  const navigateToPage = (pageName) => {
    switch (pageName) {
      case "ManagedSecurityServices":
        navigate("/managed-security-services");
        window.scrollTo(0, 0);
        break;
      case "SOCService":
        navigate("/soc-service/");
        window.scrollTo(0, 0);

        break;
      case "PenetrationTesting":
        navigate("/penetration-testing/");
        window.scrollTo(0, 0);

        break;
      case "SecurityComplianceAndAuditing":
        navigate("/security-compliance-and-auditing/");
        window.scrollTo(0, 0);

        break;
      case "EndpointSecurityServices":
        navigate("/endpoint-security-services/");
        window.scrollTo(0, 0);

        break;
      case "NetworkSecurityManagement":
        navigate("/network-security-management/");
        window.scrollTo(0, 0);

        break;
      case "SecurityEngineering":
        navigate("/security-engineering/");
        window.scrollTo(0, 0);

        break;
      case "SecurityAwarenessProgram":
        navigate("/security-awareness-program/");
        window.scrollTo(0, 0);

        break;
      case "MalwareAnalysis":
        navigate("/malware-analysis/");
        window.scrollTo(0, 0);

      default:
        console.warn("Invalid page name provided");
        break;
    }
  };

  return (
    <div style={{ backgroundColor: colors.charcoalblue }}>
      <div
        className="container"
        style={{ backgroundColor: colors.charcoalblue }}
      >
        <div className="row">
          {servicesData?.map((x, index) => (
            <div
              className="col-lg-4 col-md-6  col-xs-12"
              key={index}
              onClick={() => {
                navigateToPage(x?.routeName);
              }}
            >
              <div className="box-part text-start">
                <img src={x?.image} className="mb-3" />
                <div className="title font-face-Raleway-Bold">
                  <h5>{x?.heading}</h5>
                </div>

                <div className="text font-face-Raleway-Regular">
                  <span>{x.description}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllCloudServices;
