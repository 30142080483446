import React, { useEffect } from "react";
import CloudServiceImg from "../assets/images/Cloud Servic.gif";

import "./CloudServicesSection.css";
import { colors } from "../global/Colors";
import Aos from "aos";
import "aos/dist/aos.css";

const CloudServicesSection = () => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  return (
    <div className="CloudServicesSectionStyle">
      <div className="container py-5 text-center">
        <h1
          className="mb-4 text-white font-face-Raleway-Regular"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <span
            style={{ color: colors.green }}
            className="font-face-Raleway-Bold"
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            Cloud{" "}
          </span>
          Services
        </h1>
        <p
          className="font-face-Raleway-Regular text-center"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          Cloud Shield is a top-class Managed Security Service Provider (MSSP).
          We are assisting in reshaping our clients’ organizational abilities to
          detect, prevent, and respond actively to cyber threats and breaches of
          all nature. We aim to transform your business's cybersecurity posture
          and boost your teams’ efficiency to take your cybersecurity
          capabilities to new heights. On top of that, our services will help
          you resolve any unforeseen online incidents effectively and timely
        </p>
        <div className="row">
          <div className="col-md-12 text-white d-flex flex-column justify-content-center">
            <img src={CloudServiceImg} className="p-lg-5" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudServicesSection;
