import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import "./NavigationBar.css";
import Nav from "react-bootstrap/Nav";
import { Container } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { colors } from "../global/Colors";
import { Link } from "react-router-dom";

const NavigationBar = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;

    switch (path) {
      case "/managed-security-services":
        setActiveItem("ManagedSecurityServices");
        break;
      case "/soc-service/":
        setActiveItem("SOCService");
        break;
      case "/penetration-testing/":
        setActiveItem("PenetrationTesting");
        break;
      case "/security-compliance-and-auditing/":
        setActiveItem("SecurityComplianceAndAuditing");
        break;
      case "/endpoint-security-services/":
        setActiveItem("EndpointSecurityServices");
        break;
      case "/network-security-management/":
        setActiveItem("NetworkSecurityManagement");
        break;
      case "/security-engineering/":
        setActiveItem("SecurityEngineering");
        break;
      case "/security-awareness-program/":
        setActiveItem("SecurityAwarenessProgram");
        break;
      case "/malware-analysis/":
        setActiveItem("MalwareAnalysis");
        break;
      case "/case-studies/":
        setActiveItem("CaseStudies");
        break;
      case "/about-us":
        setActiveItem("AboutUs");
        break;
      case "/contact-us/":
        setActiveItem("ContactUs");
        break;
      case "/":
        setActiveItem("Home");
        break;
      default:
        setActiveItem(""); // If no match, reset
        break;
    }
  }, [location]);
  const handleNavItemClick = (itemName) => {
    setExpanded(false);
    setActiveItem(itemName);
  };

  const navigateToPage = (pageName) => {
    setActiveItem(pageName);
    switch (pageName) {
      case "ManagedSecurityServices":
        navigate("/managed-security-services");
        window.scrollTo(0, 0);
        break;
      case "SOCService":
        navigate("/soc-service/");
        window.scrollTo(0, 0);

        break;
      case "PenetrationTesting":
        navigate("/penetration-testing/");
        window.scrollTo(0, 0);

        break;
      case "SecurityComplianceAndAuditing":
        navigate("/security-compliance-and-auditing/");
        window.scrollTo(0, 0);

        break;
      case "EndpointSecurityServices":
        navigate("/endpoint-security-services/");
        window.scrollTo(0, 0);

        break;
      case "NetworkSecurityManagement":
        navigate("/network-security-management/");
        window.scrollTo(0, 0);

        break;
      case "SecurityEngineering":
        navigate("/security-engineering/");
        window.scrollTo(0, 0);

        break;
      case "SecurityAwarenessProgram":
        navigate("/security-awareness-program/");
        window.scrollTo(0, 0);

        break;
      case "MalwareAnalysis":
        navigate("/malware-analysis/");
        window.scrollTo(0, 0);

        break;
      case "CaseStudies":
        navigate("/case-studies/");
        window.scrollTo(0, 0);

        break;
      case "AboutUs":
        navigate("/about-us");
        window.scrollTo(0, 0);

        break;
      case "ContactUs":
        navigate("/contact-us/");
        window.scrollTo(0, 0);

        break;

      case "Home":
        navigate("/");
        window.scrollTo(0, 0);
        break;

      default:
        console.warn("Invalid page name provided");
        break;
    }
  };

  return (
    <Navbar
      expand="lg"
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      style={{ backgroundColor: colors.charcoalblue }}
      className="fixed-top"
    >
      <Container>
        <Navbar.Brand
          onClick={() => {
            handleNavItemClick();
            navigateToPage("Home");
          }}
        >
          <img
            className="brand-img"
            src={Logo}
            alt="web-logo"
            style={{ cursor: "pointer" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="ms-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link
              className={`font-face-Montserrat-Regular ${
                activeItem === "Home" ? "active" : ""
              }`}
              onClick={(e) => {
                handleNavItemClick("Home");
                navigateToPage("Home");
              }}
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/about-us"
              className={`font-face-Montserrat-Regular ${
                activeItem === "AboutUs" ? "active" : ""
              }`}
              onClick={() => {
                handleNavItemClick("AboutUs");
                navigateToPage("AboutUs");
              }}
            >
              About Us
            </Nav.Link>
            <NavDropdown
              title={
                <span
                  className={`font-face-Montserrat-Regular ${
                    location.pathname === "/managed-security-services" ||
                    location.pathname === "/soc-service/" ||
                    location.pathname === "/penetration-testing/" ||
                    location.pathname ===
                      "/security-compliance-and-auditing/" ||
                    location.pathname === "/endpoint-security-services/" ||
                    location.pathname === "/network-security-management/" ||
                    location.pathname === "/security-engineering/" ||
                    location.pathname === "/security-awareness-program/" ||
                    location.pathname === "/malware-analysis/"
                      ? "active"
                      : ""
                  }`}
                >
                  Services
                </span>
              }
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item
                as={Link}
                to="/managed-security-services"
                className={`font-face-Montserrat-Medium ${
                  activeItem === "ManagedSecurityServices"
                    ? "dropdown-active"
                    : ""
                }`}
                onClick={() => {
                  handleNavItemClick("ManagedSecurityServices");
                  navigateToPage("ManagedSecurityServices");
                }}
              >
                Managed Security Services{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/soc-service/"
                className={`font-face-Montserrat-Medium ${
                  activeItem === "SOCService" ? "dropdown-active" : ""
                }`}
                onClick={() => {
                  handleNavItemClick("SOCService");
                  navigateToPage("SOCService");
                }}
              >
                SOC as a Service
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/penetration-testing/"
                className={`font-face-Montserrat-Medium ${
                  activeItem === "PenetrationTesting" ? "dropdown-active" : ""
                }`}
                onClick={() => {
                  handleNavItemClick("PenetrationTesting");
                  navigateToPage("PenetrationTesting");
                }}
              >
                Penetration Testing
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/security-compliance-and-auditing/"
                className={`font-face-Montserrat-Medium ${
                  activeItem === "SecurityComplianceAndAuditing"
                    ? "dropdown-active"
                    : ""
                }`}
                onClick={() => {
                  handleNavItemClick("SecurityComplianceAndAuditing");
                  navigateToPage("SecurityComplianceAndAuditing");
                }}
              >
                Security Compliance And Auditing{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/endpoint-security-services/"
                className={`font-face-Montserrat-Medium ${
                  activeItem === "EndpointSecurityServices"
                    ? "dropdown-active"
                    : ""
                }`}
                onClick={() => {
                  handleNavItemClick("EndpointSecurityServices");
                  navigateToPage("EndpointSecurityServices");
                }}
              >
                Endpoint Security Services{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/network-security-management/"
                className={`font-face-Montserrat-Medium ${
                  activeItem === "NetworkSecurityManagement"
                    ? "dropdown-active"
                    : ""
                }`}
                onClick={() => {
                  handleNavItemClick("NetworkSecurityManagement");
                  navigateToPage("NetworkSecurityManagement");
                }}
              >
                Network Security Management{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/security-engineering/"
                className={`font-face-Montserrat-Medium ${
                  activeItem === "SecurityEngineering" ? "dropdown-active" : ""
                }`}
                onClick={() => {
                  handleNavItemClick("SecurityEngineering");
                  navigateToPage("SecurityEngineering");
                }}
              >
                Security Engineering{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/security-awareness-program/"
                className={`font-face-Montserrat-Medium ${
                  activeItem === "SecurityAwarenessProgram"
                    ? "dropdown-active"
                    : ""
                }`}
                onClick={() => {
                  handleNavItemClick("SecurityAwarenessProgram");
                  navigateToPage("SecurityAwarenessProgram");
                }}
              >
                Security Awareness Program{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/malware-analysis/"
                className={`font-face-Montserrat-Medium ${
                  activeItem === "MalwareAnalysis" ? "dropdown-active" : ""
                }`}
                onClick={() => {
                  handleNavItemClick("MalwareAnalysis");
                  navigateToPage("MalwareAnalysis");
                }}
              >
                Malware Analysis
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              as={Link}
              to="/case-studies/"
              className={`font-face-Montserrat-Regular ${
                activeItem === "CaseStudies" ? "active" : ""
              }`}
              onClick={() => {
                handleNavItemClick("CaseStudies");
                navigateToPage("CaseStudies");
              }}
            >
              Case Studies
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/contact-us/"
              className={`font-face-Montserrat-Regular ${
                activeItem === "ContactUs" ? "active" : ""
              }`}
              onClick={() => {
                handleNavItemClick("ContactUs");
                navigateToPage("ContactUs");
              }}
            >
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
