import React, { useEffect, useState } from "react";
import "./App.css";

import { Route, Routes } from "react-router-dom";
import Footer from "../src/layout/Footer";
import NavigationBar from "../src/layout/NavigationBar";
import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import CaseStudiesPage from "./pages/CaseStudiesPage";
import ManagedSecurityServicePage from "./pages/ManagedSecurityServicePage";
import SOCServicePage from "./pages/SOCServicePage";
import PenetrationTestingPage from "./pages/PenetrationTestingPage";
import SecurityComplianceAndAuditingPage from "./pages/SecurityComplianceAndAuditingPage";
import EndpointSecurityServicesPage from "./pages/EndpointSecurityServicesPage";
import NetworkSecurityManagementPage from "./pages/NetworkSecurityManagementPage";
import SecurityEngineeringPage from "./pages/SecurityEngineeringPage";
import SecurityAwarenessProgramPage from "./pages/SecurityAwarenessProgramPage";
import MalwareAnalysisPage from "./pages/MalwareAnalysisPage";
import emailimg from "./assets/images/email.svg";
import phoneImg from "./assets/images/reciever.svg";

import HomePage from "./pages/HomePage";
import SubscribeFormSection from "./components/SubscribeFormSection";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage.js";
import logo from "../src/assets/images/logo.png";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  const handleButton = () => {
    window.location.href = "mailto:secure@cloudshield.ai";
  };

  const handlePhoneButton = () => {
    window.location.href = "tel:+17132132908";
  };
  return (
    <div>
      {loading ? (
        <div className="loading-screen">
          <img src={logo} alt="Loading Icon" className="img-fluid" />
        </div>
      ) : (
        <>
          {/* <div
            style={{
              position: "fixed",
              bottom: "70px",
              right: "20px",
              zIndex: "9999",
              height: "50px",
              width: "50px",
            }}
          >
            <img
              src={phoneImg}
              style={{
                height: "50px",
                width: "50px",
                borderRadius: "50px",
                cursor: "pointer",
              }}
              onClick={handlePhoneButton}
              alt="phoneImg"
            />
          </div> */}

          <div
            style={{
              position: "fixed",
              bottom: "10px",
              right: "20px",
              zIndex: "9999",
              height: "50px",
              width: "50px",
            }}
          >
            <img
              src={emailimg}
              style={{
                height: "50px",
                width: "50px",
                borderRadius: "50px",
                cursor: "pointer",
              }}
              onClick={handleButton}
              alt="email"
            />
          </div>
          <NavigationBar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about-us/" element={<AboutUsPage />} />
            <Route path="/contact-us/" element={<ContactUsPage />} />
            <Route path="/case-studies/" element={<CaseStudiesPage />} />
            <Route
              path="/managed-security-services/"
              element={<ManagedSecurityServicePage />}
            />
            <Route path="/soc-service/" element={<SOCServicePage />} />
            <Route
              path="/penetration-testing/"
              element={<PenetrationTestingPage />}
            />
            <Route
              path="/security-compliance-and-auditing/"
              element={<SecurityComplianceAndAuditingPage />}
            />
            <Route
              path="/endpoint-security-services/"
              element={<EndpointSecurityServicesPage />}
            />
            <Route
              path="/network-security-management/"
              element={<NetworkSecurityManagementPage />}
            />
            <Route
              path="/security-engineering/"
              element={<SecurityEngineeringPage />}
            />
            <Route
              path="/security-awareness-program/"
              element={<SecurityAwarenessProgramPage />}
            />
            <Route
              path="/malware-analysis/"
              element={<MalwareAnalysisPage />}
            />

            <Route path="/privacy-policy/" element={<PrivacyPolicyPage />} />
            <Route
              path="/terms-and-conditions/"
              element={<TermsAndConditionsPage />}
            />
          </Routes>
          <SubscribeFormSection />
          <Footer />
        </>
      )}
    </div>
  );
};

export default App;
