import React from "react";
import CustomBanner from "../components/CustomBanner";
import ServiceOverview from "../components/ServiceOverview";
import PenetrationImg1 from "../assets/images/Penetration/Image 1.svg";
import PenetrationImg2 from "../assets/images/Penetration/Image 2.svg";
import PenetrationImg3 from "../assets/images/Penetration/Image 3.svg";
import PenetrationImg4 from "../assets/images/Penetration/Image 4.svg";
import PenetrationImg5 from "../assets/images/Penetration/Image 5.svg";

const PenetrationTestingPage = () => {
  const bannerPrimaryHeadingOne = "Penetration";
  const bannerSubHeading = "Testing";

  const bannerParagrapghTextOne =
    "Penetration Testing, the widely known term for Ethical Hacking, is a quality testing method that tries to pierce the shield of an organization's cybersecurity. We at Cloud Shield strive to provide top-class results and reports for exploitable vulnerabilities in networks, web applications, and user-centric security.";

  const bannerParagrapghTextTwo =
    "Our unparalleled strategies bring out the finest results of all the exploitable vulnerabilities. We believe in delivering results that help you improve the security checks and devise new methodologies for taking the pen testing process to a whole new level.";
  const serviceData = [
    {
      heading: "Reconnaissance",
      subheading: "Determine The Shared Information By Pen Testing",
      description:
        "Cloud Shield conducts reconnaissance in the Penetration Testing to collect primary data and information related to the organization. This information or data is already shared on the internet, and reconnaissance gives you a proper understanding to plan and devise a strategy that visible the probable attack or breach.",
      descriptionwithspace:
        "Our professional testers accumulate all the information to understand the real-time condition and state of digital footprints. Using reconnaissance, our team makes sure that all your gateways are fully secured and doesn't let an attacker exploit and tailor any settings.",
      imageSrc: PenetrationImg5,
    },
    {
      heading: "Scanning & Enumeration",
      subheading: "Don’t Let An Attacker Get Near Your System & Information",
      description:
        "Cloud Shield is a proficient organization that gathers all the shared information regarding your systems and running applications to check the current conditions. This wouldn't allow any attacker or breacher to scan or enumerate networks or applications on open ports.",
      descriptionwithoutspace:
        "This strategy will safeguard your system names, versions, user accounts, email address, machine names, services versions, or network resources.",
      imageSrc: PenetrationImg4,
    },
    {
      heading: "Exploitation",
      subheading:
        "A Profound Testing In An Attempt To Bypass Security Restrictions",
      description:
        "Cloud Shield's Exploitation in Penetration Testing is in-depth testing to try establishing access to a company's system or available resources by creating a prototype to bypass security confinements. The goal here is to see how far an ethical hacker can dig by exploiting the loopholes.",
      descriptionwithspace:
        "But with our services and penetration testing, your business gains complete control that doesn't let any target become vulnerable to breaches or cyber-attacks. Moreover, we understand the company's protocols and set parameters that our collaborated guidelines are available for initial scoping",
      imageSrc: PenetrationImg1,
    },
    {
      heading: "Privilege Escalation",
      subheading:
        "Get A Full-Cycle Access On Your Systems With Cloud Shield’s Privilege Escalation",
      description:
        "Cloud Shield’s privilege escalation is a comprehensive act of exploiting the loopholes and evaluating the bugs, flaws, and configuration errors that would lead to attacks. In this process, our team of pen testers oversees the operating system or any software application to gain access to resources that seem protected by are not in reality.",
      descriptionwithoutspace:
        "Cloud Shield's team is primarily based on certified ethical hackers who try to get an illicit gain to access elevated privileges, which aren’t particularly entitled for a user.",
      imageSrc: PenetrationImg3,
    },
    {
      heading: "Identify Vulnerabilities",
      subheading: "Get Rid Of All The Vulnerable Areas On Your Web Presence",
      description:
        "Cloud Shield strives to provide top-class services by running deep and expanded testing on your networks or website applications that have known vulnerabilities. Our pen testers conduct Vulnerability Assessment and Penetration Testing (VAPT) to counter any activity of unauthorized access or malicious activity.",
      descriptionwithoutspace:
        "When you deal with Cloud Shield, you are a step ahead to enable your system to identify and understand weaknesses in your system, underlying infrastructure, support system, and major applications.",
      imageSrc: PenetrationImg2,
    },
  ];
  return (
    <div>
      <CustomBanner
        bannerPrimaryHeadingOne={bannerPrimaryHeadingOne}
        bannerSubHeading={bannerSubHeading}
        bannerParagrapghTextOne={bannerParagrapghTextOne}
        bannerParagrapghTextTwo={bannerParagrapghTextTwo}
      />
      <ServiceOverview serviceData={serviceData} />
    </div>
  );
};

export default PenetrationTestingPage;
