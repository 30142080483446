import React, { useEffect } from "react";
import "./ServiceOverview.css";
import { colors } from "../global/Colors";
import Aos from "aos";
import "aos/dist/aos.css";

const ServiceOverview = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1800 });
  });
  return (
    <>
      {props?.serviceData?.map((x, index) => {
        const isEvenRow = index % 2 === 0;
        const rowBackgroundColor = isEvenRow
          ? colors.darkcharcoal
          : colors.charcoalblue;

        const isNotLastItem = index !== props?.serviceData?.length - 1;
        return (
          <div
            className="serviceoverview"
            style={{
              backgroundColor: props?.show
                ? colors.charcoalblue
                : rowBackgroundColor,
            }}
          >
            <div className="container py-5 text-white">
              <div
                key={index}
                className={`row ${isEvenRow ? "flex-row-reverse" : ""} ${
                  isNotLastItem ? "mb-1" : ""
                }`}
              >
                <div className="col-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
                  <h3
                    className="font-face-Raleway-Bold"
                    data-aos="zoom-in"
                    data-aos-duration="1900"
                  >
                    {x.heading}
                  </h3>
                  <h5
                    className="mb-4 font-face-Roboto-Regular"
                    data-aos="zoom-in"
                    data-aos-duration="1900"
                  >
                    {x.subheading}
                  </h5>
                  <p
                    className="font-face-Roboto-Regular text-justify"
                    data-aos="zoom-in"
                    data-aos-duration="1900"
                  >
                    {x.description}
                    <br />
                    <span
                      className="font-face-Roboto-Regular"
                      data-aos="zoom-in"
                      data-aos-duration="1900"
                    >
                      {x.descriptionwithoutspace}
                    </span>
                  </p>
                  <p
                    className="font-face-Roboto-Regular text-justify"
                    data-aos="zoom-in"
                    data-aos-duration="1900"
                  >
                    {x.descriptionwithspace}
                  </p>
                  {props?.show && (
                    <ul
                      className="font-face-Roboto-bold ms-3"
                      data-aos="zoom-in"
                      data-aos-duration="1900"
                    >
                      {x?.listpoints?.map((y, index) => {
                        return <li key={index}>{y}</li>;
                      })}
                    </ul>
                  )}
                </div>

                <div className="col-md-6 d-flex flex-column justify-content-center align-items-center pt-1 mb-4 pt-xl-0 pt-lg-0 pt-md-0 order-1 order-md-2">
                  <img
                    className="img-fluid"
                    src={x.imageSrc}
                    alt={`servicesImage ${index}`}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ServiceOverview;
