import React from "react";
import CustomBanner from "../components/CustomBanner";
import ServiceOverview from "../components/ServiceOverview";
import SOCasaServiceImg1 from "../assets/images/SOC as a Service/Image 1.svg";
import SOCasaServiceImg2 from "../assets/images/SOC as a Service/Image 2.svg";
import SOCasaServiceImg3 from "../assets/images/SOC as a Service/Image 3.svg";
import SOCasaServiceImg4 from "../assets/images/SOC as a Service/Image 4.svg";
import SOCasaServiceImg5 from "../assets/images/SOC as a Service/Image 5.svg";
import SOCasaServiceImg6 from "../assets/images/SOC as a Service/Image 6.svg";

const SOCServicePage = () => {
  const bannerPrimaryHeadingOne = "SOC";
  const bannerPrimaryHeadingTwo = "as a Service";
  const bannerParagrapghTextOne =
    " Our Security Operations Center (SOC) as a Service allows organizations of all sizes to be vigilant in their internet subsidiaries. Our security specialists help detect, prevent, and investigate any malicious threat that could be a reason for its downfall. Our approaches and drives enable us to respond to cyber threats in an effective manner.";

  const bannerParagrapghTextTwo =
    "Our SOC as a Service helps your enterprise continuously monitor and improve strategies to create a security shield to prevent and analyze cybersecurity incidents. What’s more, we provide state-of-the-art services to monitor network traffic, logs, endpoints, security, and events. Lastly, when taking services from Cloud Shield, we make sure to create a pattern to detect any suspicious activity effectively. This leads to creating an alert and further proceeds to the investigation";

  const serviceData = [
    {
      heading: "Deep Visibility & Reporting",
      subheading: "24/7 Watch On Your Infrastructure To Prevent Attacks",
      description:
        "Cloud Shield comprises a vigilant team that drives security operations 24/7. We aim to provide seamless integration and flow to your business, so you do not have to worry about anything else. During our watch and surveillance, you get updated reports and timely consultations to highlights the threats and steps are taken to eliminate them once and for all.Moreover, we create a transparent report that helps you understand your current security standpoint and further enlighten you with the strategies that help you gain a stable position in the environment.",
      imageSrc: SOCasaServiceImg6,
    },
    {
      heading: "Threat Hunting",
      subheading:
        "Game-Changing Strategies To Hunt Any Malicious Threat And Eliminate It From Roots",
      description:
        "Cloud Shield is a home for providing cutting-edge services in cybersecurity. We have built a team of hunting specialists who timely conduct their tests and check for any possible threats that could be dangerous for your internet existence.Our specialists ensure not to let anything slip through any cracks, and for this, we cover all the loopholes and checkpoints to derive a prompt strategy. Unlike many service providers, our approach doesn't let threats prevail and damage.",
      imageSrc: SOCasaServiceImg4,
    },
    {
      heading: "Compliance Modules Out of the Box",
      subheading:
        "Value-Adding Compliance Model That Gives Your Security An Edge",
      description:
        "Cloud Shield is a value-adding security service provider with top-of-the-line compliance modules to take your processes to a whole new level. Our team of experts provides cutting-edge drives to deliver impeccable revenue-generating adaptations.We follow top-class compliance standards, i.e., ISO 27001, PCI-DSS, HIPAA, GDPR, SOX, etc. When partnering with Cloud Shield for SOC as a service, we make sure that your business turns excellent profits.",
      imageSrc: SOCasaServiceImg3,
    },
    {
      heading: "Threat Intelligence Service",
      subheading:
        "Latest Tools Help You Stay Aware Of The Emerging Threats & Solutions",
      description:
        "Cloud Shield's Threat Intelligence Service provides real-time information regarding the latest emerging threats that could severely damage or harm your security and, consequently to your organization. We respond faster to incidents and proactively devise strategies to uplift your protection level against malicious threats with this strategy.Our team drives effective approaches to help you decide in a better-informed ecosystem. This action empowers enterprises of all sizes to take decisive steps to protect their users, data, information, and reputation in this tech-driven age.",
      imageSrc: SOCasaServiceImg5,
    },
    {
      heading: "File/Registry Integrity Monitoring",
      subheading:
        "Cut-Off The Injecting Malicious Payload Into Your Web Applications",
      description:
        "Cloud Shield’s File or Registry Integrity Monitoring is a world-class feature that helps us detect and record changes to a file system. Our team of experts allows you best utilize the built-in features to monitor data in an effective manner. This also helps you to detect and record all the critical alterations that have been made to the project in devising a perfect integrated model.Our high-profile team of forensic analysts keeps a careful eye to detail on any inadvertent modification to sensitive the file pathways. It assists us in verifying the integrity of operating systems and application software files to evaluate if any threat or attack has tried to temper the application",
      imageSrc: SOCasaServiceImg2,
    },
    {
      heading: "Incident Handling & Response",
      subheading:
        "Cater To Any Malware Outbreak or Mis-clicking With Our Quality Handlers",
      description:
        "Cloud Shield consists of a proactive team of incident handlers and solution providers. We help you manage and respond to any type of security incidents ranging from misclicking by any users or malware outbursts. Upon finding any sort of breach or interruption, we drive our swift methodology to contain the breach and recover the compromised systems.While recovering, we strive to bear minimal collateral damage so that our partnered enterprise doesn’t suffer at the hands of malicious threats. This is what sets us apart from our competitors in the process",
      imageSrc: SOCasaServiceImg1,
    },
  ];
  return (
    <div>
      {" "}
      <CustomBanner
        bannerPrimaryHeadingOne={bannerPrimaryHeadingOne}
        bannerPrimaryHeadingTwo={bannerPrimaryHeadingTwo}
        bannerParagrapghTextOne={bannerParagrapghTextOne}
        bannerParagrapghTextTwo={bannerParagrapghTextTwo}
      />
      <ServiceOverview serviceData={serviceData} />
    </div>
  );
};

export default SOCServicePage;
