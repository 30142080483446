import React, { useEffect, useRef } from "react";
import heroImageHome from "../assets/images/heroImageHomeGif.gif";
import scrolldown from "../assets/images/scroll down.svg";
import { colors } from "../global/Colors";
import "./HeroImage.css";
import CustomButton from "./CustomButton";
import Aos from "aos";
import "aos/dist/aos.css";

const HeroImage = () => {
  // Create a reference for the next section
  const nextSectionRef = useRef(null);

  useEffect(() => {
    Aos.init({ duration: 1800 });
  }, []);

  // Function to scroll to the next section and start at the top
  const scrollToNextSection = () => {
    if (nextSectionRef.current) {
      const offset = 80; // Adjust this value based on your header height or padding

      const sectionTop =
        nextSectionRef.current.getBoundingClientRect().top + window.pageYOffset;
      const targetPosition = sectionTop - offset;

      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="heroSection mt-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 text-white d-flex flex-column justify-content-center">
            <h1
              className="mb-4 font-face-Raleway-Regular"
              data-aos="zoom-in"
              data-aos-duration="1900"
            >
              <span
                style={{ color: colors.green }}
                className="font-face-Raleway-Bold"
              >
                Security
              </span>{" "}
              Like Never Before{" "}
            </h1>
            <p
              className="font-face-Roboto-Regular text-justify"
              data-aos="zoom-in"
              data-aos-duration="1900"
            >
              Cloud Shield delivers unmatched security like never before. With
              cutting-edge solutions, we provide proactive defense, real-time
              monitoring, and expert incident response to protect your
              organization from cyber threats.
            </p>

            <div
              className="custom-btn"
              data-aos="zoom-in"
              data-aos-duration="1900"
            >
              <CustomButton btnText="Learn More" pagePath={"/about-us/"} />
            </div>
          </div>

          <div className="col-md-6 d-flex flex-column align-items-center justify-content-center mt-3">
            <img
              className="img-fluid"
              src={heroImageHome}
              alt="heroImageHome"
            />
          </div>
        </div>
        <div
          onClick={scrollToNextSection}
          style={{ cursor: "pointer" }}
          className="d-flex flex-column scroll-down-animation align-items-center justify-content-center mt-5"
        >
          <img
            src={scrolldown}
            alt="scrolldown"
            className="" // Apply the animation class
          />
          <p className="font-face-Roboto-Regular mt-2">Scroll Down</p>
        </div>
      </div>

      {/* Next Section */}
      <div ref={nextSectionRef} className="next-section">
        {/* Add your content for the next section here */}
        {/* <h2>Next Section Title</h2>
        <p>This is the content of the next section.</p> */}
      </div>
    </div>
  );
};

export default HeroImage;
